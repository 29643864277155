import { Button, Form, Modal } from 'react-bootstrap'
import ReactTable from '../../tables/react_table'
import * as React from 'react'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, Formik } from 'formik'
import { Input } from '../../form/forms'
import { FormikSelect } from '../../form/formik'
import { INDICATOR_TYPES } from '../../../screens/database/financing/constant'
import { getLabelFromOptions, getUuid } from '../../../utils/utils'

export const YearIndicator = ({ formik, yearIndicator }) => {

    const [showModal, setShowModal] = useState(false)

    const labels = {
        year: 'В каком году договор учитывается по указанному справа показателю',
        indicator: 'Показатель, в котором учитывается этот договор / заказ',
        delete: 'Удалить',
    }

    const handleDelete = (element) => {
        const newValue = yearIndicator?.filter((item) => item?.id !== element?.id)
        formik.setFieldValue('year_indicator', [...newValue])

    }

    const handleAdd = (values) => {
        const previousValue = yearIndicator?.length ? yearIndicator : []
        formik.setFieldValue('year_indicator', [...previousValue, { ...values, id: getUuid() }])
        setShowModal(false)
    }

    const additionalCheckField = (field, key, element) => {
        if (key === 'delete') {
            return (
                <Button variant={'danger'} onClick={() => handleDelete(element)}>
                    <FontAwesomeIcon icon={'trash-alt'}/>
                </Button>
            )
        } else if (key === 'indicator') {
            return (
                <span>{getLabelFromOptions(INDICATOR_TYPES, field)}</span>
            )
        }
        return field
    }

    return (
        <>
            <Modal size={'lg'} show={showModal} centered={true}
                   onHide={() => setShowModal(false)}>
                <Modal.Header>
                    <Modal.Title>
                        Добавление года учета в показателе
                    </Modal.Title>
                    <button className={'close'} onClick={() => {
                        setShowModal(false)
                    }}><FontAwesomeIcon icon={'times'}/></button>
                </Modal.Header>
                <Formik
                    initialValues={{ year: '', indicator: null }}
                    onSubmit={(values) => handleAdd(values)}>
                    {(props) => (
                        <Form>
                            <Modal.Body>
                                <Input name={'year'} id={'year'}
                                       label={'Год'}
                                       max={2100}
                                       min={1900}
                                       type={'year'}
                                       handleChangeValue={props.handleChange}
                                />
                                <Field component={FormikSelect}
                                       name={'indicator'}
                                       label={'Показатель, в котором учитывается этот договор / заказ'}
                                       isClearable={true}
                                       options={INDICATOR_TYPES}
                                       id={'indicator'}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={'success'} onClick={props.submitForm}>
                                    Добавить
                                </Button>
                                <Button variant={'danger'}
                                        onClick={() => setShowModal(false)}>
                                    Отменить
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
            <Button className={'mb-2'} onClick={() => setShowModal(true)}>
                Добавить год учета в показателе
            </Button>
            <ReactTable
                bordered={true}
                hover={true}
                striped={true}
                additionalCheckField={additionalCheckField}
                data={{ results: yearIndicator }}
                show_result={false}
                labels={labels}/>
            <hr/>
        </>
    )
}
