import * as React from 'react'
import { Button, Card } from 'react-bootstrap'
import { getRequest, putRequest } from '../../actions/common'
import ReactBreadcrumb from '../../components/breadcrumbs'
import { Check, Input, Select, Area } from '../../components/form/forms'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../../components/form/formik'


const UserCreate = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            middle_name: '',
            username: '',
            email: '',
            project: '',
            roles: [],
            projects: [],
            description: '',
            is_active: 'on',
            show_password: false,
            not_notification: false,
        },
        onSubmit: (values) => onSubmitForm(values),
        validateOnBlur: false,
        validateOnChange: false,
    })
    const [select_values, setSelectValues] = useState({})
    const [allProjects, setAllProjects] = useState(null)
    const [errors, setErrors] = useState({ last_name: null, first_name: null, email: null })

    React.useEffect(() => {
        document.title = 'Создать пользователя | ИС «ПИШ»'
    })

    useEffect(() => {
        if (!allProjects) {
            getRequest('project', setAllProjects, { type_data: 'all' })
        }
    }, [allProjects])

    const onSubmitForm = (e) => {
        e.preventDefault()
        let data = { data: {}, additional: {} }
        data.data = {
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            middle_name: formik.values.middle_name,
            username: formik.values.username,
            email: formik.values.email,
            project: formik.values.project,
            roles: formik.values.roles,
            projects: formik.values.projects,
            description: formik.values.description,
        }

        data.additional = {
            show_password: formik.values.show_password,
            not_notification: formik.values.not_notification,
        }

        putRequest('user', data).then((response) => {
            if (response.status === 400) {
                let err = response?.data
                if (err) {
                    setErrors({ ...err })
                }
            } else {
                if (response.status === 201) {
                    setErrors({ last_name: null, first_name: null, email: null })
                    let password = (response?.data?.password)
                    toast.success(`Пользователь успешно создан!\n${password ? `Установленный пароль: ${password}` : ''}`, {
                        position: 'bottom-right',
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        theme: 'colored',
                    })
                    navigate('/users/')
                }
            }
        })
    }

    const generateOptions = (id) => {
        if (allProjects) {
            let options = []
            allProjects.forEach((element) => {
                options.push({ value: element.id, label: element.title_short })
            })
            return options
        }
        return null
    }

    const refreshFilter = () => {
        document.getElementById('id').value = ''
        document.getElementById('title').value = ''
        document.getElementById('description').value = ''
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Создать
            </h1>
            <form onSubmit={onSubmitForm}>
                <Card>
                    <FormikProvider value={formik}>
                        <Card.Body>
                            <Input label={'Фамилия'} id={'last_name'}
                                   value={formik.values.last_name}
                                   handleChangeValue={formik.handleChange}
                                   required={true}
                                   invalid={errors.last_name}
                                   error={errors.last_name}
                            />
                            <Input label={'Имя'} id={'first_name'} required={true}
                                   value={formik.values.first_name}
                                   handleChangeValue={formik.handleChange}
                                   invalid={errors.first_name}
                                   error={errors.first_name}
                            />
                            <Input label={'Отчество'} id={'middle_name'}
                                   handleChangeValue={formik.handleChange}
                                   value={formik.values.middle_name}
                            />
                            <Input label={'Email адрес'} required={true} id={'email'}
                                   value={formik.values.email}
                                   handleChangeValue={formik.handleChange}
                                   invalid={errors.email}
                                   error={errors.email}
                            />
                            <Field component={FormikSelect}
                                   label={'Проекты'} 
                                   id={'projects'}
                                   name={'projects'}
                                   isMulti={true}
                                   isClearable={false}
                                   handleChangeValue={formik.handleChange}
                                   options={generateOptions()}
                            />
                            <Field component={FormikSelect}
                                   label={'Роли'} id={'roles'}
                                   name={'roles'}
                                   isMulti={true}
                                   isClearable={false}
                                   handleChangeValue={formik.handleChange}
                                   options={[
                                       { value: 'project_manager', label: 'Менеджер проекта' },
                                       { value: 'direction_financing', label: 'Дирекция софин' },
                                       { value: 'direction_staff', label: 'Дирекция кадры' },
                                       { value: 'direction_edu', label: 'Дирекция образование' },
                                       { value: 'direction_rid', label: 'Дирекция РИД' },
                                       { value: 'direction_oop', label: 'Дирекция ООП' },
                                       { value: 'direction_dpo', label: 'Дирекция ДПО' },
                                       { value: 'direction', label: 'Дирекция' },
                                       { value: 'admin', label: 'Администратор' },
                                       { value: 'management', label: 'Руководители' },
                                       { value: 'inspector', label: 'Инспектор' },
                                   ]}
                            />
                            <Area label={'Описание'} id={'description'}
                                  name={'description'}
                                  value={formik.values.description}
                                  handleChangeValue={formik.handleChange}/>
                            <Check label={'Показать пароль'}
                                   id={'show_password'}
                                   props={{ data_field: 'additional' }}
                                   value={formik.values.not_notification}
                                   handleChangeValue={formik.handleChange}
                            />
                            <Check label={'Не уведомлять пользователя'}
                                   id={'not_notification'}
                                   name={'not_notification'}
                                   value={formik.values.not_notification}
                                   handleChangeValue={formik.handleChange}
                                   props={{ data_field: 'additional' }}
                            />
                        </Card.Body>
                    </FormikProvider>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button variant='success' type='submit'>Сохранить</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </form>
        </React.Fragment>
    )
}

export default UserCreate
