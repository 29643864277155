import * as React from 'react'
import * as ReactDOM from 'react-dom/client'

import App from './App'
import { ToastContainer } from 'react-toastify'
import { Alert } from 'react-bootstrap'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<React.Fragment>
        {process.env.REACT_APP_ENV !== 'production'
            ? <Alert variant={'danger'} className={'m-0'}>
                Внимание! Это тестовый стенд!
            </Alert>
            : null}
        <App/>
        <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'/>
    </React.Fragment>,
)
