import * as React from 'react'
import { Table } from 'react-bootstrap'

const ReactTableIndicator = ({ data, indicators, total, header_data }) => {

    const addOrUpdateTotal = (dict, field, element) => {
        if (Object.keys(dict).includes(field)) {
            dict[field]['value'] += element.value
            dict[field]['value_plan'] += element.value_plan
            dict[field]['value_passport'] += element.value_passport
        } else {
            dict[field] = {}
            dict[field]['value'] = element.value
            dict[field]['value_plan'] = element.value_plan
            dict[field]['value_passport'] = element.value_passport
        }
    }

    const round = (value) => {
        return Math.round(value * 100) / 100
    }

    const TableHeader = () => {
        if (data && header_data) {
            let values_component = <React.Fragment>
                <td className='text-center bg-light'>План</td>
                <td className='text-center bg-light'>Факт</td>
            </React.Fragment>
            let elements = []
            let elements_values = [<React.Fragment key={'total_values'}>{values_component}</React.Fragment>]

            elements.push(<React.Fragment key={'header'}>
                    <th style={{ position: 'sticky', left: 0, minWidth: '45px' }} className='bg-light' rowSpan='2'>#</th>
                    <th style={{ position: 'sticky', left: '45px', minWidth: '350px' }} className='bg-light' rowSpan='2'>
                        Показатель
                    </th>
                    <th style={{ position: 'sticky', left: '45px', minWidth: '350px' }} className='bg-light' rowSpan='2'>
                        Наименование показателя
                    </th>
                    <th style={{ position: 'sticky', left: '395px', minWidth: '100px' }} className='bg-light' rowSpan='2'>
                        Сокращенное наименование
                    </th>
                    <th width='300' className='text-center bg-light' colSpan='2'
                        key={'total'}>Конечный результат
                    </th>
                </React.Fragment>,
            )
            header_data?.forEach((element) => {
                elements.push(<th width='300' className='text-center bg-light' colSpan='2'
                                  key={element.title_short}>{element.title_short}</th>)
                elements_values.push(<React.Fragment key={`${element.title_short}_values`}>
                    {values_component}
                </React.Fragment>)
            })

            return <React.Fragment>
                <tr>{elements}</tr>
                <tr>{elements_values}</tr>
            </React.Fragment>
        }
        return null
    }

    const TableBody = () => {
        if (data && indicators) {
            let rows = []
            let i = 1

            indicators?.forEach((indicator) => {
                if (Object.keys(total).includes(indicator.title_short)) {
                    let bgClass = indicator?.parent_id ? 'purple' : 'light'
                    let bgColor = indicator?.parent_id ? 'rgba(61,7,255,0.24)' : ''

                    let elements = [<React.Fragment key={`${i}_left_header`}>
                        <th style={{ position: 'sticky', left: 0, maxWidth: '45px' }}
                            className={`bg-${bgClass}`}>
                            {i}
                        </th>
                        <th style={{
                            position: 'sticky',
                            left: '45px',
                            minWidth: '350px',
                        }}
                            className={`text-left bg-${bgClass}`}>{indicator.title}</th>
                        <th style={{
                            position: 'sticky',
                            left: '45px',
                            minWidth: '350px',
                        }}
                            className={`text-left bg-${bgClass}`}>{indicator.abbreviation}</th>
                        <th style={{
                            position: 'sticky',
                            left: '395px',
                            minWidth: '100px',
                        }}
                            className={`bg-${bgClass}`}>{indicator.unit}</th>
                    </React.Fragment>]
                    let j = 1
                    data?.forEach((element) => {
                        if (indicator.id === element.indicator.id) {
                            elements.push(<React.Fragment key={`${i}_${j}_value`}>
                                <td style={{ backgroundColor: bgColor }}>
                                    {round(element.value_plan)}
                                </td>
                                <td style={{ backgroundColor: bgColor }}>
                                    {round(element.value)}
                                </td>
                            </React.Fragment>)
                        }
                        j += 1
                    })
                    elements.splice(1, 0, <React.Fragment key={`${i}_${j}_total_value`}>
                        <td style={{ backgroundColor: bgColor }}>
                            {round(total[indicator.title_short].value_plan)}
                        </td>
                        <td style={{ backgroundColor: bgColor }}>
                            {round(total[indicator.title_short].value)}
                        </td>
                    </React.Fragment>)
                    rows.push(<tr key={`${i}_row`}>{elements}</tr>)
                    i += 1
                }
            })
            return rows
        }
        return null
    }

    return <React.Fragment>
        <div style={{ width: '100%', maxHeight: '800px', overflow: 'auto' }}>
            <Table size={'md'} bordered={true} hover={true} style={{ borderCollapse: 'separate', borderSpacing: 0 }}>
                <thead style={{ position: 'sticky', top: 0, zIndex: 2 }}><TableHeader/></thead>
                <tbody><TableBody/></tbody>
            </Table>
        </div>
    </React.Fragment>
}

export default ReactTableIndicator
