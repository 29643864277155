import * as React from 'react'
import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Area, Input } from '../../form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, useFormik } from 'formik'
import { IconText } from '../../icon_txt'
import { toast } from 'react-toastify'
import { MONTHS } from '../../../screens/database/niokr/constant'


export const YearResults = ({ formik, yearResults, disabled }) => {
    const { userHasPermissions } = useAbac()
    const [show_modal, setShowModal] = useState(false)
    const [delete_year, setDeleteYear] = useState(null)

    const CAN_MANAGE_YEAR = userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || userHasPermissions(PERMISSIONS.ADMIN_PANEL)

    const formikHelper = useFormik({
        initialValues: {},
    })

    useEffect(() => {
        if (delete_year) {
            let years = formik.values.year_result
            let i
            for (i = 0; i < years.length; i++) {
                if (years[i].year === delete_year) {
                    break
                }
            }
            setDeleteYear(null)
            years.splice(i, 1)
        }
    }, [formik.values, delete_year])

    const handleAddYear = (values) => {
        if (!CAN_MANAGE_YEAR) {
            setShowModal(false)
            return
        }

        if (formik.values.year_result.find((item) => item?.year === Number(values?.year))) {
            toast.error('Введенный год уже присутствует в фактических значениях')
            return
        }
        formik.setFieldValue('year_result', [...formik.values.year_result, {
            year: values?.year, result: '', month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                month: 1, results: '', accepted: false, needAcceptResult: false,
            }, 0, 12),
        }])
        formikHelper.setValues({
            ...formikHelper.values, [values?.year]: {
                visible: false,
            },
        })
        setShowModal(false)
    }


    return (<>
        <Modal size={'md'} show={show_modal} centered={true} onHide={() => setShowModal(false)}>
            <div className={'modal-content'}>
                <Modal.Header>
                    <Modal.Title>
                        Добавление результата за год
                    </Modal.Title>
                    <button className={'close'} onClick={() => {
                        setShowModal(false)
                    }}><FontAwesomeIcon icon={'times'}/>
                    </button>
                </Modal.Header>
                <Formik
                    initialValues={{ year: '' }}
                    onSubmit={(values) => handleAddYear(values)}>
                    {(props) => (<Form>
                        <Modal.Body>
                            <Input name={'year'} id={'year'}
                                   label={'Год'}
                                   max={2100}
                                   min={1900}
                                   type={'year'}
                                   handleChangeValue={props.handleChange}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className={'button-actions'}>
                                <Button variant={'success'}
                                        onClick={() => props.submitForm()}>
                                    <IconText icon={'plus'} text={'Создать'}/>
                                </Button>
                                <Button variant={'warning'} onClick={() => {
                                    setShowModal(false)
                                }}>
                                    Отменить
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>)}
                </Formik>
            </div>
        </Modal>

        <legend>Результаты по годам</legend>
        {CAN_MANAGE_YEAR && <Button className={'mb-4'}
                                    variant={'success'}
                                    onClick={() => setShowModal(true)}
        >
            Добавить год
        </Button>}

        {formik.values?.year_result ? formik.values?.year_result?.map((item, index) => (
            <fieldset key={item?.year} className={'mb-3'}>
                <legend>Результаты за {item?.year}</legend>
                <Button
                    onClick={() => formikHelper.setFieldValue(`${item?.year}.visible`, !formikHelper.values?.[item?.year]?.visible)}>
                    {formikHelper.values?.[item?.year]?.visible ? 'Скрыть' : 'Показать'}
                </Button>
                {CAN_MANAGE_YEAR && <Button variant={'danger'} className={'ml-2'}
                                            onClick={() => setDeleteYear(item?.year)}>
                    <FontAwesomeIcon icon={'trash-alt'}/>
                </Button>}
                {
                    formikHelper.values?.[item?.year]?.visible
                        ? yearResults[index].month_results?.map((month_res, month_index) => <div
                            key={`year-${index}-${month_index}`} className={'mt-3'}>
                            <Area
                                label={MONTHS[month_index + 1]}
                                id={`year_result.${index}.month_results.${month_index}.results`}
                                name={`year_result.${index}.month_results.${month_index}.results`}
                                handleChangeValue={formik.handleChange}
                                value={month_res.results}
                                step={'any'}
                                disabled={disabled || !CAN_MANAGE_YEAR}
                                error={formik.errors[`month_results.${month_index}.results`]}
                                invalid={formik.errors[`month_results.${month_index}.results`]}
                            />
                        </div>)
                        : null
                }
            </fieldset>)) : null}
    </>)
}

