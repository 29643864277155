import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EVENT_TYPE, FIELDS, IS_NETWORK, LABELS, STATUS_BADGE_CLASS, STATUS_LABELS } from './constant'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { AuthContext } from '../../../auth'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { putRequest } from '../../../actions/common'
import { STATUS_ACCEPT, STATUS_NEW } from './constant'
import { Messages } from '../../../utils/utils'
import { useAbac } from 'react-abac'

const Dpo = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [dpo, setDpo] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'project', 'event_name', 'event_type', 'development_date', 'implementation_date', 'is_network'],
        'Скрытые / Фиксированные колонки': [
            'dev_order_number',
            'dev_order_date',
            'impl_order_number',
            'impl_order_date',
            'messages',
            'updated_by',
            'created_at',
            'updated_at',
            'created_by',
            'direction_by',
        ],
    })

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DPO_DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({ status_not: STATUS_NEW })
        }
    }, [user])

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        }
        if (key === 'event_type') {
            return EVENT_TYPE.find((v) => v?.value === field)?.['label']
        }
        if (key === 'is_network') {
            return IS_NETWORK.find((v) => v?.value === field)?.['label']
        }
        if (['speciality', 'project'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'publication'}/>
        }

        return null
    }

    const addNewDpo = (state) => {
        putRequest('dpo', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW, 
            project: user?.project?.id,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/dpo/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                ДПО
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.project_manager)
            || user?.roles?.includes(ROLES.direction_dpo)
                ? <React.Fragment>
                    <div className={'button-actions'}>
                        {!user?.roles?.includes(ROLES.direction_dpo)
                            ? <Button variant={'success'} className={'mb-3'} onClick={() => {
                                addNewDpo()
                            }}>
                                <IconText icon={'plus'} text={'Создать'}/>
                            </Button> : null}
                        {user?.roles?.includes(ROLES.admin)
                        || user?.roles?.includes(ROLES.direction_dpo)
                        || user?.roles?.includes(ROLES.direction)
                            ? <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                                addNewDpo({ monitoring: true })
                            }}>
                                <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                            </Button> : null}
                    </div>
                </React.Fragment> : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={dpo} setFunc={setDpo} page={1} url={'/dpo'}
                              url_update={'/dpo/item'}
                              get_title={'dpo'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_DPO}
                              fields={FIELDS}
            />
        </React.Fragment>
    )
}

export default Dpo
