import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters/react_table_filters'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { AuthContext } from '../../../auth'
import { EVENT_TYPES, FIELDS, LABELS, STATUS_BADGE_CLASS, STATUS_LABELS } from './constant'
import { putRequest } from '../../../actions/common'
import { STATUS_ACCEPT, STATUS_NEW } from '../publication/constant'
import { getLabelFromOptions, Messages } from '../../../utils/utils'
import { useAbac } from 'react-abac'
import { IS_NETWORK } from '../dpo/constant'


const EducationalProgram = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [edu, setEdu] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'event_name',
            'event_type',
            'speciality',
            'partners',
            'supervisor',
            'responsible_edu',
            'start',
            'end'],
        'Скрытые / Фиксированные колонки': [
            'is_network',
            'direction_by',
            'updated_by',
            'created_by',
            'created_at',
            'updated_at',
            'messages',
        ],
    })

    const checkField = (field, key) => {
        if (key === 'status') {
            if (key === 'status') {
                return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
            }
        }
        if (key === 'event_type') {
            let label = EVENT_TYPES.find((v) => v?.value === field)?.['label']
            return label ? label : <span className={'not-set'}>(не задано)</span>
        }
        if (key === 'is_network') {
            return IS_NETWORK.find((v) => v?.value === field)?.['label']
        }
        if (['project', 'supervisor', 'speciality'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'educational_program'}/>
        }

        return null
    }

    useEffect(() => {
        let filters = { project: undefined }
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            filters = { ...filters, status_not: STATUS_NEW }
        }
        if (userHasPermissions(PERMISSIONS.IS_MANAGER)) {
            filters = { ...filters, supervisor: user?.project?.id }
        }
        setFiltering(filters)
    }, [user])

    const addNewEducationalProgram = (state) => {
        putRequest('educational_program', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW, 
            project: user?.project?.id,
            is_draft: true,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/educational_program/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Образовательные программы
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction)
            || user?.roles?.includes(ROLES.project_manager) || user?.roles?.includes(ROLES.direction_oop)
                ? <React.Fragment>
                    <div className={'button-actions'}>
                        {userHasPermissions(PERMISSIONS.CONTROL_OOP)
                            ? <React.Fragment>
                                <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                                    addNewEducationalProgram({ monitoring: true })
                                }}>
                                    <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                                </Button>
                                {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                                    ? <Button variant={'success'} className={'mb-3'} onClick={() => {
                                        addNewEducationalProgram()
                                    }}>
                                        <IconText icon={'plus'} text={'Создать'}/>
                                    </Button> : null}
                            </React.Fragment>
                            : null
                        }
                    </div>
                </React.Fragment> : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={edu} setFunc={setEdu} page={1} url={'/educational_program'}
                              url_update={'/educational_program/item'}
                              get_title={'educational_program'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_OOP}
                              fields={FIELDS}
            />
        </React.Fragment>
    )
}

export default EducationalProgram
