import * as React from 'react'
import { useContext } from 'react'
import { IconText } from '../components/icon_txt'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { DropdownSubmenu } from 'react-bootstrap-submenu'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { logoutSubmit } from '../actions/auth'
import { AuthContext } from '../auth'
import { PERMISSIONS, ROLES_LABELS } from '../rbac/constant'
import { useAbac } from 'react-abac'

const Home = () => {
    const navigate = useNavigate()

    React.useEffect(() => {
        if (user && user.roles.includes('inspector')) {
            navigate('/file-manager')
        }

        document.title = 'Главная | ИС «ПИШ»'
    })

    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const logoutHandle = (e) => {
        e.preventDefault()
        logoutSubmit()
    }

    const handleFeedback = () => {
        if (process.env.REACT_APP_FEEDBACK_URL) {
            window.open(
                process.env.REACT_APP_FEEDBACK_URL,
                '_blank',
                'noreferrer',
            )
        }
    }

    const MenuButton = ({ href_url, icon, title }) => {
        return (
            <React.Fragment>
                <ListGroupItem>
                    <Link to={href_url} style={{ width: '100%', height: '100%', display: 'block', color: 'inherit' }}>
                        <IconText text={title} icon={icon}/>
                    </Link>
                </ListGroupItem>
            </React.Fragment>
        )
    }

    const SubMenuButton = ({ icon, title, children }) => {
        return (
            <React.Fragment>
                <ListGroupItem>
                    <DropdownSubmenu href='#' title={<IconText text={title} icon={icon}/>}>
                        {children}
                    </DropdownSubmenu>
                </ListGroupItem>
            </React.Fragment>
        )
    }

    const CustomListGroup = ({ lead, children, col_size }) => {
        return (
            <React.Fragment>
                <div className={`col-lg-${col_size}`}>
                    {lead ? <p className='lead'>{lead}</p> : <React.Fragment/>}
                    <ListGroup className='list-group'>
                        {children}
                    </ListGroup>
                </div>
            </React.Fragment>
        )
    }

    const Roles = () => {
        if (user) {
            let elements = []
            user.roles.forEach((role) => {
                elements.push(<span key={role}
                                    className={`badge ${role === 'admin' ? 'badge-danger' : 'badge-secondary'}`}>
                    {ROLES_LABELS[role]}
                </span>)
            })
            return elements
        }
        return null
    }

    const Projects = () => {
        if (user) {
            let elements = []
            user.projects.forEach((project) => {
                elements.push(<>
                    <span key={project} className={'badge badge-secondary'}>
                        {project['title_short']}
                    </span>
                    &nbsp;
                </>)
            })

            return elements
        }
        
        return <>Не задано</>
    }

    return (
        <React.Fragment>
            <h1 className={'page-header d-flex flex-row justify-content-between'}>
                Главная
                {process.env.REACT_APP_ENV !== 'production'
                    ? <Button variant={'secondary'} onClick={handleFeedback}>
                        <IconText icon={'comments'} text={'Оставить обратную связь'}/>
                    </Button>
                    : null}
            </h1>
            <div className={'card-deck mb-4'}>
                <Card bg={'light'}>
                    <Card.Body>
                        <div className={'row'}>
                            <CustomListGroup lead={'Мониторинг'} col_size={6}>
                                <MenuButton href_url={'/indicator'} icon={'thermometer-half'}
                                            title={'Показатели'}/>
                                <MenuButton href_url={'/indicator-project'} icon={'project-diagram'}
                                            title={'Показатели по проектам'}/>
                                <MenuButton href_url={'/indicator/report'} icon={'file-alt'}
                                            title={'Отчеты'}/>
                                <MenuButton href_url={userHasPermissions(PERMISSIONS.IS_MANAGER)
                                    ? `/grant/${user?.project?.id}?year=${new Date().getFullYear()}`
                                    : '/grant'}
                                            icon={'file-alt'}
                                            title={'Грант'}/>
                                {/*<MenuButton href_url={"/indicator/report_fin"} icon={'file-alt'}*/}
                                {/*            title={"Отчеты софинансирования"}/>*/}
                                <MenuButton href_url={'control_oop'} icon={'graduation-cap'}
                                            title={'Контроль разработки ООП'}/>
                            </CustomListGroup>
                            <CustomListGroup lead={'Дашборд'} col_size={6}>
                                <MenuButton href_url={'/dashboard'} icon={'chart-line'}
                                            title={'Открыть'}/>
                                <MenuButton href_url={'/dashboard-report'} icon={'file-alt'}
                                            title={'Данные'}/>
                            </CustomListGroup>
                        </div>
                    </Card.Body>
                </Card>
                <Card bg={'light'}>
                    <Card.Body>
                        <div className={'row'}>
                            <CustomListGroup lead={'Базы данных'} col_size={12}>
                                {/*<MenuButton href_url={"/staff"} icon={'user-friends'}*/}
                                {/*            title={"Кадры"}/>*/}
                                <MenuButton href_url={'/specialist'} icon={'user-graduate'}
                                            title={'Обученные и студенты'}/>
                                {/*<MenuButton href_url={"/publication"} icon={'book'}*/}
                                {/*            title={"Публикации"}/>*/}
                                <DropdownButton
                                    key={'end'}
                                    id={'dropdown-button-drop-end'}
                                    drop={'end'}
                                    variant={'transparent'}
                                    className={'dropright list-group-item btn-group button-dropdown'}
                                    title={<IconText text={'РИДы'} icon={'lightbulb'}/>}
                                >
                                    {userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                        && <Link to={'/rid/rid-external'}
                                                 role={'button'}
                                                 className={'dropdown-item'}
                                        >
                                            <IconText text={'РИДы (внешний мониторинг)'}
                                                      icon={'lightbulb'}
                                            />
                                        </Link>
                                    }
                                    <Link to={'/rid/rid-internal'} role={'button'} className={'dropdown-item'}>
                                        <IconText text={'РИДы (внутренний мониторинг)'} icon={'lightbulb'}/>
                                    </Link>
                                </DropdownButton>
                                <MenuButton href_url={'/financing'} icon={'coins'}
                                            title={'Доход / Софинансирование'}/>
                                <MenuButton href_url={'/internships'} icon={'address-card'}
                                            title={'Стажировка'}/>
                                <MenuButton href_url={'/educational_program'} icon={'graduation-cap'}
                                            title={'Образовательные программы'}/>
                                <MenuButton href_url={'/niokr'} icon={'coins'}
                                            title={'НИОКР'}/>
                                <MenuButton href_url={'/dpo'} icon={'lightbulb'}
                                            title={'ДПО'}/>
                                <MenuButton href_url={'/school'} icon={'school'}
                                            title={'Школьники'}/>
                                <MenuButton href_url={'/employed'} icon={'briefcase'}
                                            title={'Трудоустроенные'}/>
                                <MenuButton href_url={'/educational_spaces'} icon={'graduation-cap'}
                                            title={'Образовательные пространства'}/>
                            </CustomListGroup>
                        </div>
                    </Card.Body>
                </Card>

            </div>
            <div className={'card-deck mb-4'}>
                <Card bg={'light'}>
                    <Card.Body>
                        <p className='lead'>Справочники</p>
                        <div className={'row'}>
                            <CustomListGroup lead={''} col_size={6}>
                                <MenuButton href_url={'/dicts/self-academic-degree'} icon={'award'}
                                            title={'Ученые степени'}/>
                                <MenuButton href_url={'/dicts/self-academic-rank'} icon={'graduation-cap'}
                                            title={'Ученые звания'}/>
                                <MenuButton href_url={'/dicts/project'} icon={'project-diagram'}
                                            title={'Проекты'}/>
                                <MenuButton href_url={'/dicts/unit'} icon={'ruler-horizontal'}
                                            title={'Единицы измерения'}/>
                                <MenuButton href_url={'/dicts/staff-category'} icon={'user-tag'}
                                            title={'Категории работников'}/>
                                <MenuButton href_url={'/dicts/job-contract'} icon={'file-contract'}
                                            title={'Формы привлечения работников'}/>
                                {/* <MenuButton href_url={"/dicts/event-category"} icon={"calendar-alt"}
                                            title={"Категории мероприятий"}/>
                                <MenuButton href_url={"/dicts/event"} icon={"calendar-alt"}
                                            title={"Мероприятия"}/> */}
                                <MenuButton href_url={'/dicts/organization'} icon={'building'}
                                            title={'Контрагенты'}/>
                                <MenuButton href_url={'/dicts/patent-office'} icon={'shield-alt'}
                                            title={'Патентные ведомства'}/>
                                <MenuButton href_url={'/dicts/speciality'} icon={'user-tag'}
                                            title={'Специальность и направления подготовки'}/>
                                {user?.roles.every((value) => value !== 'project_manager')
                                    ? <MenuButton href_url={'/dicts/person'} icon={'user-friends'}
                                                  title={'Персоналии'}/>
                                    : null
                                }
                                <MenuButton href_url={'/dicts/school-event'} icon={'award'}
                                            title={'Типы мероприятий для БД "Школьники"'}/>
                            </CustomListGroup>
                            <CustomListGroup lead={''} col_size={6}>

                                <MenuButton href_url={'/dicts/department'} icon={'sitemap'}
                                            title={'Подразделения'}/>
                                <MenuButton href_url={'/dicts/academic-degree'} icon={'award'}
                                            title={'Ученые степени (внешний источник)'}/>
                                <MenuButton href_url={'/dicts/academic-rank'} icon={'graduation-cap'}
                                            title={'Ученые звания (внешний источник)'}/>
                                <MenuButton href_url={'/dicts/country'} icon={'globe'}
                                            title={'Страны'}/>
                                <MenuButton href_url={'/dicts/staff-position'} icon={'street-view'}
                                            title={'Должности'}/>
                                <MenuButton href_url={'/dicts/staff-unit'} icon={'user-shield'}
                                            title={'Штатные единицы'}/>
                                <MenuButton href_url={'/dicts/order'} icon={'book'}
                                            title={'Приказы'}/>
                                <MenuButton href_url={'/dicts/template'} icon={'book'}
                                            title={'Шаблоны'}/>
                                <MenuButton href_url={'/dicts/template-oop'} icon={'book'}
                                            title={'Шаблоны документов для открытия ООП'}/>
                                <MenuButton href_url={'/dicts/deadlines-oop'} icon={'calendar-alt'}
                                            title={'Сроки добавления документов по ООП'}/>

                            </CustomListGroup>
                        </div>
                    </Card.Body>
                </Card>
                <Card bg={'light'}>
                    <Card.Body>
                        <div className={'row'}>
                            <CustomListGroup lead={'Профиль'} col_size={12}>
                                <ListGroupItem className={'list-unstyled'}>
                                    <li>
                                        <strong>ФИО</strong>: {user ? user.last_name : null} {user ? user.first_name : null} {user ? user.middle_name : null}
                                    </li>
                                    <li><strong>Email адрес</strong>: {user ? user.email : null}</li>
                                    <li><strong>Роли</strong>: <Roles/></li>
                                    <li><strong>Проект</strong>: <Projects/></li>
                                    <li className='button-actions mb-0 mt-2'>
                                        <Link to={'/forget'}>
                                            <Button variant='outline-secondary'>
                                                <IconText text={'Смена пароля'} icon={'key'}/>
                                            </Button>
                                        </Link>
                                        <Button variant='outline-danger' onClick={logoutHandle}>
                                            <IconText text={'Выход'} icon={'sign-out-alt'}/>
                                        </Button>
                                    </li>
                                </ListGroupItem>
                            </CustomListGroup>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default Home
