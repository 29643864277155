import * as React from 'react'
import DictField from './common/item'
import {DownloadFile, FileList} from '../../components/file_manager/file_handlers'

export const AcademicDegreeField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       dict_title={'academic_degree'}
                       url_update={'/dicts/academic-degree/update'}
            />
        </>
    )
}


export const AcademicRankField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/academic-rank/update'}
                       dict_title={'academic_rank'}
            />
        </>
    )
}


export const CountryField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/country/update'}
                       dict_title={'country'}
            />
        </>
    )
}

export const DepartamentField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'parent', 'is_active', 'date_create', 'date_close', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'parent': 'ID родителя',
        'is_active': 'Активно?',
        'date_create': 'Дата открытия',
        'date_close': 'Дата закрытия',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       dict_title={'department'}
            />
        </>
    )
}

export const EventCategoryField = (props) => {
    const fields = [
        'id', 'title', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/event-category/update'}
                       dict_title={'event_category'}
            />
        </>
    )
}


export const EventsField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id', 'category', 'project', 'is_educational',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
        'category': 'Категория',
        'project': 'Проект',
        'is_educational': 'Образовательное',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/event/update'}
                       dict_title={'event'}
            />
        </>
    )
}

export const JobContractField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/job-contract/update'}
                       dict_title={'job_contract'}
            />
        </>
    )
}

export const OrganizationField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Наименование заказчика (контрагента)',
        'title_short': 'ИНН заказчика(контрагента)',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/organization/update'}
                       dict_title={'organization'}
            />
        </>
    )
}

export const PatentOfficeField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/patent-office/update'}
                       dict_title={'patent_office'}
            />
        </>
    )
}

export const ProjectField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/project/update'}
                       dict_title={'project'}
            />
        </>
    )
}

export const SelfAcademicDegreeField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/self-academic-degree/update'}
                       dict_title={'self_academic_degree'}
            />
        </>
    )
}

export const SelfAcademicRankField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/self-academic-rank/update'}
                       dict_title={'self_academic_rank'}
            />
        </>
    )
}

export const StaffCategoryField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/staff-category/update'}
                       dict_title={'staff_category'}
            />
        </>
    )
}

export const StaffPositionField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/staff-position/update'}
                       dict_title={'staff_position'}
            />
        </>
    )
}

export const StaffUnitField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/staff-unit/update'}
                       dict_title={'staff_unit'}
            />
        </>
    )
}

export const UnitField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'title_short': 'Аббревиатура',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/unit/update'}
                       dict_title={'unit'}
            />
        </>
    )
}

export const DeadlinesOopField = (props) => {
    const fields = [
        'id', 'title', 'date', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Наименование этапа',
        'date': 'Срок загрузки документа',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/deadlines-oop/update'}
                       dict_title={'deadlines_oop'}
            />
        </>
    )
}

export const SpecialityField = (props) => {
    const fields = [
        'id', 'title', 'title_short', 'ref_id',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Направление подготовки',
        'title_short': 'Специальность и направление подготовки',
        'sort_index': 'Порядковый №',
        'ref_id': 'Внешняя ссылка',
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/speciality/update'}
                       dict_title={'speciality'}
            />
        </>
    )
}


export const PersonField = (props) => {
    const fields = [
        'id', 'employee_id', 'last_name', 'first_name', 'middle_name', 'birth_date',
        'employment_date', 'dismissal_date', 'scientific_accounts', 'country', 'department',
        'self_academic_degree', 'academic_rank', 'created_at', 'updated_at',
    ]
    const labels = {
        'id': 'ID',
        'employee_id': 'Внешняя ссылка',
        'last_name': 'Фамилия',
        'first_name': 'Имя',
        'middle_name': 'Отчество',
        'age': 'Возраст',
        'birth_date': 'Дата рождения',
        'employment_date': 'Дата приема',
        'dismissal_date': 'Дата увольнения',
        'scientific_accounts': 'Научные идентификаторы',
        'country': 'Гражданство',
        'department': 'Основное подразделение',
        'self_academic_degree': 'Ученая степень',
        'academic_rank': 'Ученое звание',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления',
    }

    const additional_labels = {
        'position': 'Должность',
        'department': 'Основное подразделение',
        'unit': 'Штатная единица',
        'account_num': 'Лицевой счет',
        'is_main': 'Основная должность',
        'created_at': 'Время создания',
        'cupdated_at': 'Время обновления',
    }

    const additionalCheckField = (field, key) => {
        if (['country', 'self_academic_degree', 'academic_rank'].includes(key)) {
            return field ? field.title : null
        } else if (key === 'scientific_accounts') {
            let elements = []
            for (let account in field) {
                elements.push(
                    <div key={account}>
                        <span className='badge badge-secondary'>{account}</span>
                        {` ${field[account]}`}
                    </div>,
                )
            }
            return field ? elements : null
        }
        return null
    }

    const tableCheckField = (field, key) => {
        if (['unit', 'department', 'position'].includes(key)) {
            return field ? field.title : null
        }
        return null
    }

    return (
        <>
            <DictField labels={labels}
                       fields={fields}
                       url_update={''}
                       dict_title={'person'}
                       document_header={'fio'}
                       header={'fio'}
                       additional_table={'person_position'}
                       additional_table_header={'Должности'}
                       additional_field={'person_id'}
                       additional_labels={additional_labels}
                       tableCheckField={tableCheckField}
                       additionalCheckField={additionalCheckField}
            />
        </>
    )
}

export const OrderField = (props) => {
    const fields = [
        'id', 'title', 'description', 'filename',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'description': 'Описание',
        'filename': 'Файл',
    }

    const additionalCheckField = (field, key) => {
        if (key === 'filename') {
            return <DownloadFile folder={'orders'} file={field} title={'order'}/>
        }
        return null
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/order/update'}
                       additionalCheckField={additionalCheckField}
                       dict_title={'order'}
            />
        </>
    )
}

export const TemplateField = (props) => {
    const fields = [
        'id', 'title', 'description', 'filename', 'created_at', 'updated_at',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'description': 'Описание',
        'filename': 'Файл',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления',
    }

    const additionalCheckField = (field, key) => {
        if (key === 'filename') {
            return <DownloadFile folder={'templates'} file={field} title={'template'}/>
        }
        return null
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/template/update'}
                       additionalCheckField={additionalCheckField}
                       dict_title={'template'}
            />
        </>
    )
}

export const TemplateOopField = (props) => {
    const fields = [
        'id', 'title', 'files', 'created_at', 'updated_at',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Заголовок',
        'files': 'Файлы',
        'created_at': 'Время создания',
        'updated_at': 'Время обновления',
    }

    const additionalCheckField = (field, key) => {
        if (key === 'files') {
            return (
                <FileList files={field} folder={'template_oop'} title={'template_oop'} fileAsLabel/>
            )
        }
        return null
    }

    return (
        <>
            <DictField labels={labels} fields={fields}
                       url_update={'/dicts/template-oop/update'}
                       additionalCheckField={additionalCheckField}
                       dict_title={'template_oop'}
            />
        </>
    )
}

export const SchoolEventField = (props) => {
    const fields = [
        'title', 'variants', 'sort_index',
    ]
    const labels = {
        'id': 'ID',
        'title': 'Тип мероприятия',
        'variants': 'Варианты мероприятий',
        'sort_index': 'Порядковый №',
    }


    return (
        <>
            <DictField labels={labels} fields={fields}
                       dict_title={'school_event'}
                       url_update={'/dicts/school-event/update'}
            />
        </>
    )
}
