import { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconText } from '../../icon_txt'
import Sortable from '../../sortable/sortable'
import { Input } from '../../form/forms'

export const SettingsModalWindow = ({ props, 
                                      showSettings,
                                      setShowSettings,
                                      groups, 
                                      setGroups, 
                                      pageLimit, 
                                      setPageLimit,
                                    }) => (
    <Modal size={'lg'} 
           show={showSettings} 
           centered={true}
           onHide={() => setShowSettings(false)}
    >
        <div className={'modal-content'}>
            <Modal.Header>
                <Modal.Title>
                    <IconText icon={'wrench'} text={'Настройка таблицы'}/>
                </Modal.Title>
                <button className={'close'} 
                        onClick={() => setShowSettings(false)}
                >
                    <FontAwesomeIcon icon={'times'}/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={4}>
                        <Input label={'Записей на странице'}
                               id={'table_page_size'}
                               small={'Число от 0 и до 100'}
                               type={'number'}
                               max={100}
                               min={10}
                               value={pageLimit}
                        />
                    </Col>
                    <div className={'sortable-column-label'}> 
                        Настроить порядок и отображение колонок
                    </div>
                    <Sortable groups={groups} labels={props.labels} setGroups={setGroups}/>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'danger'} 
                        onClick={() => {
                            window.location.reload()
                            localStorage.removeItem(`groups_${props.get_title}`)
                        }}
                >
                    <IconText text={'Удалить'} icon={'trash-alt'}/>
                </Button>
                <Button variant={'secondary'} 
                        onClick={() => setGroups(props.groups)}
                >
                    <IconText text={'Сбросить'} icon={'redo'}/>
                </Button>
                <Button onClick={() => {
                    props.setGroups(groups)
                    let page_size = document.getElementById('table_page_size')
                    if (page_size) {
                        setPageLimit(Number(page_size.value))
                    }
                    setShowSettings(false)
                }}>
                    <IconText text={'Применить'} icon={'save'}/>
                </Button>
            </Modal.Footer>
        </div>
    </Modal>
)

export const SettingsButton = ({ setShowSettings }) => (
    <Button variant={'outline-secondary'}
            className={'mr-1'} 
            onClick={() => setShowSettings(true)}>
        <FontAwesomeIcon icon={'wrench'}/>
    </Button>
)
