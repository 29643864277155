import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconText } from '../../icon_txt'

export const TableModal = ({ setFunc, show, icon, title, body, footer }) => {
    return <Modal size={'lg'} show={show} centered={true} onHide={() => setFunc(false)}>
        <div className={'modal-content'}>
            <Modal.Header>
                <Modal.Title>
                    <IconText icon={icon} text={title}/>
                </Modal.Title>
                <button className={'close'}
                        onClick={() => setFunc(false)}
                >
                    <FontAwesomeIcon icon={'times'}/>
                </button>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </div>
    </Modal>
}
