import * as React from 'react'
import { useEffect, useState } from 'react'
import './assets/css/App.css'
import './utils/fontawesome'

import 'react-toastify/dist/ReactToastify.css'

import SiteRoutes from './utils/routes/routes'
import { AuthContextProvider } from './auth'
import { CookiesProvider } from 'react-cookie'
import { Loader } from './utils/loader'


export const App = () => {
    const [isLoad, setLoad] = useState(false)
    console.log(process.env)

    useEffect(() => {
        const lastVersion = localStorage.getItem('x-version')

        if (lastVersion !== process.env.REACT_APP_VERSION) {
            localStorage.clear()
            localStorage.setItem('x-version', process.env.REACT_APP_VERSION)
            location.reload()
        }

        if (process.env.REACT_APP_ENV !== 'production') {
            import('./assets/css/dev_bootstrap.scss').then((r) => setLoad(true))
        } else {
            import('./assets/css/bootstrap.scss').then((r) => setLoad(true))
        }
    }, [])

    return <>
        {
            isLoad
                ? <Loader>
                    <AuthContextProvider>
                        <CookiesProvider>
                            <SiteRoutes/>
                        </CookiesProvider>
                    </AuthContextProvider>
                </Loader>
                : null
        }
    </>
}

export default App




