import * as React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { IconText } from './icon_txt'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../auth'
import { useAbac } from 'react-abac'
import { PERMISSIONS, ROLES } from '../rbac/constant'
import { logoutSubmit } from '../actions/auth'
import { Link } from 'react-router-dom'

const Header = () => {
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const CustomNavDropDown = ({ name, icon, children }) => {
        const title = <IconText text={name} icon={icon}/>
        return (
            <React.Fragment>
                <NavDropdown title={title}>
                    {children}
                </NavDropdown>
            </React.Fragment>
        )
    }

    const logoutHandle = (e) => {
        e.preventDefault()
        logoutSubmit()
    }

    return (
        <React.Fragment>
            <Navbar bg='dark' variant='dark' expand='md'>
                <Link to={'/'}><Navbar.Brand>ИС «ПИШ»</Navbar.Brand></Link>
                <Navbar.Toggle aria-controls='basic-navbar-nav'/>
                <Navbar.Collapse id='basic-navbar-nav'>
                    { !userHasPermissions(PERMISSIONS.IS_INSPECTOR)
                        && <Nav className='me-auto'>
                            <CustomNavDropDown name={'Мониторинг'} icon={'desktop'}>
                                <Link to={'/indicator'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Показатели'} icon={'thermometer-half'}/>
                                </Link>
                                <Link to={'/indicator-project'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Показатели по проектам'} icon={'project-diagram'}/>
                                </Link>
                                <Link to={'/indicator/report'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Отчеты'} icon={'file-alt'}/>
                                </Link>
                                <Link to={userHasPermissions(PERMISSIONS.IS_MANAGER)
                                    ? `/grant/${user?.project?.id}?year=${new Date().getFullYear()}`
                                    : '/grant'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Грант'} icon={'file-alt'}/>
                                </Link>
                                <Link to={'/control_oop'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Контроль разработки ООП'} icon={'graduation-cap'}/>
                                </Link>
                            </CustomNavDropDown>
                            <CustomNavDropDown name={'Дашборд'} icon={'chart-line'}>
                                <Link to={'/dashboard'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Открыть'} icon={'sign-in-alt'}/>
                                </Link>
                                <Link to={'/dashboard-report'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Данные'} icon={'file-alt'}/>
                                </Link>
                            </CustomNavDropDown>
                            <CustomNavDropDown name={'Базы данных'} icon={'database'}>
                                {/*<Link to={'/staff'} role={'button'} className={'dropdown-item'}>*/}
                                {/*    <IconText text={'Кадры'} icon={'user-friends'}/>*/}
                                {/*</Link>*/}
                                <Link to={'/specialist'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Обученные и студенты'} icon={'user-graduate'}/>
                                </Link>
                                <DropdownButton
                                    key={'end'}
                                    id={'dropdown-button-drop-end'}
                                    drop={'end'}
                                    variant={'transparent'}
                                    className={'btn-group dropright dropdown-item button-dropdown'}
                                    title={<IconText text={'РИДы'} icon={'lightbulb'}/>}
                                    >
                                    { userHasPermissions(PERMISSIONS.DIRECTION_PANEL)    
                                        && <Link to={'/rid/rid-external'} 
                                                role={'button'} 
                                                className={'dropdown-item'}
                                            >
                                            <IconText text={'РИДы (внешний мониторинг)'} 
                                                        icon={'lightbulb'}
                                            />
                                        </Link>
                                    }
                                    <Link to={'/rid/rid-internal'} role={'button'} className={'dropdown-item'}>
                                        <IconText text={'РИДы (внутренний мониторинг)'} icon={'lightbulb'}/>
                                    </Link>
                                </DropdownButton>
                                {/*<Link to={'/publication'} role={'button'} className={'dropdown-item'}>*/}
                                {/*    <IconText text={'Публикации'} icon={'book'}/>*/}
                                {/*</Link>*/}
                                <Link to={'/financing'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Доход / Софинансирование'} icon={'coins'}/>
                                </Link>
                                <Link to={'/internships'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Стажировка'} icon={'address-card'}/>
                                </Link>
                                <Link to={'/educational_program'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Образовательные программы'} icon={'graduation-cap'}/>
                                </Link>
                                <Link to={'/niokr'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'НИОКР'} icon={'coins'}/>
                                </Link>
                                <Link to={'/dpo'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'ДПО'} icon={'lightbulb'}/>
                                </Link>
                                <Link to={'/school'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Школьники'} icon={'school'}/>
                                </Link>
                                <Link to={'/employed'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Трудоустроенные'} icon={'briefcase'}/>
                                </Link>
                                <Link to={'/educational_spaces'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Образовательные пространства'} icon={'graduation-cap'}/>
                                </Link>

                            </CustomNavDropDown>
                            <CustomNavDropDown name={'Справочники'} icon={'book'}>
                                <NavDropdown.Header>Собственные</NavDropdown.Header>
                                <Link to={'/dicts/self-academic-degree'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Ученые степени'} icon={'award'}/>
                                </Link>
                                <Link to={'/dicts/self-academic-rank'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Ученые звания'} icon={'graduation-cap'}/>
                                </Link>
                                <Link to={'/dicts/project'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Проекты'} icon={'project-diagram'}/>
                                </Link>
                                <Link to={'/dicts/unit'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Единицы измерения'} icon={'ruler-horizontal'}/>
                                </Link>
                                <Link to={'/dicts/staff-category'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Категории работников'} icon={'user-tag'}/>
                                </Link>
                                <Link to={'/dicts/job-contract'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Формы привлечения работника'} icon={'file-contract'}/>
                                </Link>
                                {/* <Link to={'/dicts/event'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Мероприятия'} icon={'calendar-alt'}/>
                                </Link> */}
                                <Link to={'/dicts/organization'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Контрагенты'} icon={'building'}/>
                                </Link>
                                <Link to={'/dicts/patent-office'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Платные ведомства'} icon={'shield-alt'}/>
                                </Link>
                                <Link to={'/dicts/order'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Приказы'} icon={'book'}/>
                                </Link>
                                <Link to={'/dicts/template'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Шаблоны'} icon={'book'}/>
                                </Link>
                                <Link to={'/dicts/template-oop'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Шаблоны документов для открытия ООП'} icon={'book'}/>
                                </Link>
                                <Link to={'/dicts/deadlines-oop'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Сроки добавления документов по ООП'} icon={'calendar-alt'}/>
                                </Link>
                                <Link to={'/dicts/speciality'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Специальность и направления подготовки'} icon={'user-tag'}/>
                                </Link>
                                <Link to={'/dicts/school-event'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Типы мероприятий для БД "Школьники"'} icon={'award'}/>
                                </Link>
                                <NavDropdown.Header>Внешние</NavDropdown.Header>
                                <Link to={'/dicts/department'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Подразделения'} icon={'sitemap'}/>
                                </Link>
                                <Link to={'/dicts/academic-degree'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Ученые степени'} icon={'award'}/>
                                </Link>
                                <Link to={'/dicts/academic-rank'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Ученые звания'} icon={'graduation-cap'}/>
                                </Link>
                                <NavDropdown.Header>Внешние (возможность редактирования)</NavDropdown.Header>
                                <Link to={'/dicts/country'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Страны'} icon={'globe'}/>
                                </Link>
                                <Link to={'/dicts/staff-position'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Должности'} icon={'street-view'}/>
                                </Link>
                                <Link to={'/dicts/staff-unit'} role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Штатные единицы'} icon={'user-shield'}/>
                                </Link>
                            </CustomNavDropDown>
                            {user?.roles.every((value) => value !== 'project_manager')
                                ? <CustomNavDropDown name={'Импорт'} icon={'file-import'}>
                                    <Link to={'/import'} role={'button'} className={'dropdown-item'}>
                                        <IconText text={'Импорт данных'} icon={'file-import'}/>
                                    </Link>
                                </CustomNavDropDown>
                                : null}
                        </Nav>
                    }
                    <Nav className='ml-auto'>
                        <CustomNavDropDown name={`${user?.fio}`} icon={'user'}>
                            {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                                ? <Link to='/users' role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Пользователи'} icon={'users'}/>
                                </Link> : null
                            }
                            {userHasPermissions(PERMISSIONS.CONTROL_FILE_MANAGER)
                                ? <React.Fragment>
                                    <Link to={'/file-manager'} role={'button'} className={'dropdown-item'}>
                                        <IconText text={'Файловый менеджер'} icon={'folder-open'}/>
                                    </Link>
                                    {/* <Link to={'/'} role={'button'} className={'dropdown-item'} disabled={true}>
                                        <IconText text={'Настройки'} icon={'cog'}/>
                                    </Link> */}
                                </React.Fragment> : null
                            }
                            { !userHasPermissions(PERMISSIONS.IS_INSPECTOR)
                                && <Link to='/forget' role={'button'} className={'dropdown-item'}>
                                    <IconText text={'Смена пароля'} icon={'key'}/>
                                </Link>
                            }
                            <NavDropdown.Item onClick={logoutHandle}>
                                <IconText text={'Выход'} icon={'sign-out-alt'}/>
                            </NavDropdown.Item>
                        </CustomNavDropDown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </React.Fragment>
    )
}

export default Header
