import * as React from 'react'
import * as Yup from 'yup'

import {
    Button,
    Card, Col, Row,
} from 'react-bootstrap'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Input } from '../../../components/form/forms'
import { putRequest } from '../../../actions/common'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'

export const REPORT_YEAR = [
    { value: '2022', label: 2022 },
    { value: '2023', label: 2023 },
    { value: '2024', label: 2024 },
    { value: '2025', label: 2025 },
    { value: '2026', label: 2026 },
    { value: '2027', label: 2027 },
    { value: '2028', label: 2028 },
    { value: '2029', label: 2029 },
    { value: '2030', label: 2030 },
]

export const REPORT_MONTH = [
    { value: '01', label: 'Январь' },
    { value: '02', label: 'Февраль' },
    { value: '03', label: 'Март' },
    { value: '04', label: 'Апрель' },
    { value: '05', label: 'Май' },
    { value: '06', label: 'Июнь' },
    { value: '07', label: 'Июль' },
    { value: '08', label: 'Август' },
    { value: '09', label: 'Сентябрь' },
    { value: '10', label: 'Октябрь' },
    { value: '11', label: 'Ноябрь' },
    { value: '12', label: 'Декабрь' },
]


const ReportCreate = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            title: null,
            start_date: null,
            end_date: null,
            month_start: null,
            year_start: null,
            month_end: null,
            year_end: null,
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required('Обязательное поле!'),
            month_start: Yup.string().nullable().required('Обязательное поле!'),
            year_start: Yup.string().nullable().required('Обязательное поле!'),
            month_end: Yup.string().nullable().required('Обязательное поле!'),
            year_end: Yup.string().nullable().required('Обязательное поле!'),
        }),
        onSubmit: (values) => saveReport(),
        validateOnChange: false,
        validateOnBlur: false,
    })

    React.useEffect(() => {
        if (!formik.isValid) {
            toast.error('Ошибка в заполнении данных!')
        }
    }, [formik.isValid])

    const saveReport = () => {
        putRequest('report', formik.values).then((response) => {
            if (response.status === 201) {
                navigate('/indicator/report')
            }
            else if (response.status === 400) {
                if (response.data?.message)
                    toast.error(response.data?.message)
                if (response.data?.fields)
                    formik.setErrors({ ...formik.errors, ...response.data?.fields })
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Сгенерировать отчет
            </h1>

            <Card>
                <FormikProvider value={formik}>
                    <Card.Body>
                        <Input required={true}
                               id={'title'}
                               name={'title'}
                               value={formik.values.title}
                               label={'Заголовок'}
                               handleChangeValue={formik.handleChange}
                               error={formik.errors.title}
                               invalid={formik.errors.title}
                        />
                        <Row>
                            <Col md={3}>
                                <Field component={FormikSelect}
                                        name={'month_start'} label={'Месяц начала периода'}
                                        id={'month_start'}
                                        isSearchable={true}
                                        isClearable={true}
                                        options={REPORT_MONTH}
                                        value={formik.values.month_start}
                                        handleChangeValue={formik.handleChange}
                                        error={formik.errors['month_start']}
                                        invalid={formik.errors['month_start']}
                                        required={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Field component={FormikSelect}
                                        name={'year_start'} label={'Год начала периода'}
                                        id={'year_start'}
                                        isSearchable={true}
                                        isClearable={true}
                                        options={REPORT_YEAR}
                                        value={formik.values.year_start}
                                        handleChangeValue={formik.handleChange}
                                        error={formik.errors['year_start']}
                                        invalid={formik.errors['year_start']}
                                        required={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Field component={FormikSelect}
                                        name={'month_end'} label={'Месяц окончания периода'}
                                        id={'month_end'}
                                        isSearchable={true}
                                        isClearable={true}
                                        options={REPORT_MONTH}
                                        value={formik.values.month_end}
                                        handleChangeValue={formik.handleChange}
                                        error={formik.errors['month_end']}
                                        invalid={formik.errors['month_end']}
                                        required={true}
                                />
                            </Col>
                            <Col md={3}>
                                <Field component={FormikSelect}
                                        name={'year_end'} label={'Год окончания периода'}
                                        id={'year_end'}
                                        isSearchable={true}
                                        isClearable={true}
                                        options={REPORT_YEAR}
                                        value={formik.values.year_end}
                                        handleChangeValue={formik.handleChange}
                                        error={formik.errors['year_end']}
                                        invalid={formik.errors['year_end']}
                                        required={true}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </FormikProvider>
                <Card.Footer>
                    <div className={'button-actions'}>
                        <Button variant={'success'} onClick={formik.submitForm}>Сгенерировать отчет</Button>
                        <Button variant={'secondary'} onClick={() => {
                            formik.setValues(formik.initialValues)
                        }}>Сбросить</Button>
                    </div>
                </Card.Footer>
            </Card>
        </React.Fragment>
    )
}

export default ReportCreate
