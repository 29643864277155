import { DatabaseItem } from './common'
import { StaffItem } from './staff/item'
import { SpecialistItem } from './specialist/item'
import { RidInternalItem } from './rid/rid_internal/item'
import { NiokrItem } from './niokr/item'
import { PublicationItem } from './publication/item'
import { FinancingItem } from './financing/item'
import { AmortizationItem } from './amortization/item'
import { InternshipsItem } from './internships/item'
import { EducationalProgramItem } from './educational_program/item'
import { ControlOopItem } from './control_oop/item'
import { DpoItem } from './dpo/item'
import { SchoolItem } from './school/item'
import { EmployedItem } from './employed/item'
import { EducationalSpacesItem } from './educational_spaces/item'

export const DatabaseItemStaff = () => {
    return <DatabaseItem title={'staff'}>
        <StaffItem/>
    </DatabaseItem>
}

export const DatabaseItemSpecialist = () => {
    return <DatabaseItem title={'specialist'}>
        <SpecialistItem/>
    </DatabaseItem>
}

export const DatabaseItemRidInternal = () => {
    return <DatabaseItem title={'rid_internal'}>
        <RidInternalItem/>
    </DatabaseItem>
}

export const DatabaseItemInternships = () => {
    return <DatabaseItem title={'internships'}>
        <InternshipsItem/>
    </DatabaseItem>
}

export const DatabaseItemEducationalProgram = () => {
    return <DatabaseItem title={'educational_program'}>
        <EducationalProgramItem/>
    </DatabaseItem>
}

export const DatabaseItemControlOop = () => {
    return <DatabaseItem title={'control_oop'}>
        <ControlOopItem/>
    </DatabaseItem>
}

export const DatabaseItemDpo = () => {
    return <DatabaseItem title={'dpo'}>
        <DpoItem/>
    </DatabaseItem>
}

export const DatabaseItemSchool = () => {
    return <DatabaseItem title={'school'}>
        <SchoolItem/>
    </DatabaseItem>
}

export const DatabaseItemPublication = () => {
    return <DatabaseItem title={'publication'}>
        <PublicationItem/>
    </DatabaseItem>
}

export const DatabaseItemFinancing = () => {
    return <DatabaseItem title={'financing'}>
        <FinancingItem/>
    </DatabaseItem>
}

export const DatabaseItemAmortization = () => {
    return <DatabaseItem title={'amortization'}>
        <AmortizationItem/>
    </DatabaseItem>
}

export const DatabaseItemNiokr = () => {
    return <DatabaseItem title={'niokr'}>
        <NiokrItem/>
    </DatabaseItem>
}

export const DatabaseItemEmployed = () => {
    return <DatabaseItem title={'employed'}>
        <EmployedItem/>
    </DatabaseItem>
}

export const DatabaseItemEducationalSpaces = () => {
    return <DatabaseItem title={'educational_spaces'}>
        <EducationalSpacesItem/>
    </DatabaseItem>
}

