import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import { getRequest, putRequest } from '../../../actions/common'
import { STATUS_ACCEPT, STATUS_APPROVE, STATUS_NEW, STATUS_PROCESS } from './constant'
import { Area, Input, Select } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { setChangedValue } from '../../../utils/utils'
import { AuthContext } from '../../../auth'
import { REQUIRED_FIELDS } from './constant'
import { toast } from 'react-toastify'
import { Field } from 'formik'
import { FormikSelect } from '../../../components/form/formik'

export const NiokrCreate = (props) => {
    const { state } = useLocation()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()
    const navigate = useNavigate()

    const [input_values, setInputValues] = useState({ common: {} })
    const [select_data, setSelectData] = useState({})
    const [select_values, setSelectValues] = useState({})
    const [errors, setErrors] = useState(() => {
        let temp = {}
        REQUIRED_FIELDS[STATUS_ACCEPT].forEach((field) => {
            temp[field] = null
        })
        return temp
    })

    const select_fields = ['person']

    useEffect(() => {
        if (!userHasPermissions(PERMISSIONS.MANAGER_PANEL)) {
            navigate('/access-error')
        } else {
            document.title = 'Создание записи в БД НИОКР | ИС «ПИШ»'
            if (user?.project?.id) {
                setSelectValues({ ...select_values, project: user?.project?.id })
            }
        }
    })

    useEffect(() => {
        if (Object.keys(select_data).length < select_fields.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(field)) {
                    getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                    break
                }
            }
        }
    }, [select_data])

    const getStatusClassName = (status) => {
        if (status === STATUS_NEW) {
            return 'bg-primary'
        } else if (status < STATUS_NEW) {
            return 'bg-success'
        } else if (status > STATUS_NEW) {
            return 'bg-secondary'
        }

        return null
    }

    const handleChangeValue = (e) => {
        e.preventDefault()
        let target = e.target.getAttribute('data_change_key')
        let sub_key = e.target.getAttribute('data_foreign_id')
        setChangedValue(setInputValues, input_values, target, e.target.id, e.target.value, sub_key)
    }

    const generateOptions = (field, key) => {
        if (select_data && Object.keys(select_data).includes(field)) {
            let options = []
            for (let element of select_data[field]) {
                options.push({ value: element.id, label: element[key] })
            }
            return options
        }
        return null
    }

    const checkErrors = (status) => {
        let new_errors = {}
        let expression
        let has_errors = false

        REQUIRED_FIELDS[status].forEach((field) => {
            if (typeof select_values[field] === 'object') {
                expression = !select_values[field]?.length
            } else {
                expression = !document.getElementById(field)?.value && (!select_values[`${field}_id`] && !select_values[field])
            }
            if (expression) {
                new_errors[field] = 'Обязательное поле!'
                has_errors = true
            } else {
                new_errors[field] = null
            }
        })
        setErrors({ ...errors, ...new_errors })
        return has_errors
    }

    const saveNiokr = () => {
        let payload = { ...input_values['common'], ...(select_values) }
        payload['status'] = state?.monitoring ? STATUS_ACCEPT : STATUS_NEW
        putRequest('niokr', payload).then((response) => {
            if (response.status === 201) {
                navigate(`/niokr/item/${response.data.id}?update=true`)
            }
        })
    }

    const handleSumitForm = (e) => {
        e.preventDefault()
        if (checkErrors(STATUS_NEW)) {
            toast.error('Ошибка в заполнении данных!')
            return null
        }
        saveNiokr()
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <Form onSubmit={handleSumitForm}>
                <div className='section-header sticky-top'>
                    <h1 className='page-header'>
                        {'Создание записи в БД НИОКР'}
                    </h1>
                    <div className='button-actions'>
                        <Button variant='success' type='submit'>Создать</Button>
                    </div>
                </div>
                <div
                    className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                    <div className='mx-2 mb-2' style={{ minWidth: '250px' }}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_NEW)}`}>Новый</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className='mx-2 mb-2' style={{ minWidth: '250px' }}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_APPROVE)}`}>Рассматривается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className='mx-2 mb-2' style={{ minWidth: '250px' }}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_PROCESS)}`}>Обрабатывается</p>
                    </div>
                    <FontAwesomeIcon icon={'long-arrow-alt-right'} className={'text-dark mb-2 fa-2x'}/>
                    <div className='mx-2 mb-2' style={{ minWidth: '250px' }}>
                        <p className={`m-0 p-2 rounded ${getStatusClassName(STATUS_ACCEPT)}`}>Принято</p>
                    </div>
                </div>

                <Card className={'bg-light mb-3 mt-3'}>
                    <Card.Body>
                        <p>
                            <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                            условные обозначения,
                            используемые в интерфейсе:
                        </p>
                        <ul>
                            <li>поля помеченные <strong className='text-danger'>красной «*»</strong> являются <strong>обязательными
                                на текущем
                                этапе для перехода на следующий</strong>;
                            </li>
                            <li>поля помеченные <strong className='text-success'>зелёной «*»</strong> являются <strong>обязательными
                                для
                                определенных условий</strong>;
                            </li>
                            <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет сохранение
                                текущего состояния карточки (требуется
                                заполнения ключевых полей);
                            </li>
                            <li>кнопка <span
                                className='badge badge-success'>Сохранить + отправить далее</span> сохраняет карточку и
                                проверяет выполнение
                                условий текущего этапа (при выполнении условий карточка переходит на следующий этап).
                            </li>
                        </ul>
                        <hr/>
                        <fieldset>
                            {/*<legend>Личные данные</legend>*/}

                            <Input label={'Тип НИОКР'} id={'type'}
                                   required={REQUIRED_FIELDS?.[data?.status]?.includes('type')}
                                   handleChangeValue={handleChangeValue}
                            />
                            <Input label={'Наименование мероприятия/работы/этапа'} id={'title'}
                                   required={REQUIRED_FIELDS?.[data?.status]?.includes('title')}
                                   handleChangeValue={handleChangeValue}
                            />
                            <Row>
                                <Col md={6}>
                                    <Input label={'Индустриальный партнер'} id={'partner'}
                                           required={REQUIRED_FIELDS?.[data?.status]?.includes('partner')}
                                           handleChangeValue={handleChangeValue}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Field component={FormikSelect} name={'person'}
                                           label={'Исполнитель'}
                                           id={'person_id'}
                                           required={data ? REQUIRED_FIELDS[data.status].includes('person') : false}
                                           isClearable={true}
                                           options={generateOptions('person', 'fio', select_data)}
                                           error={formik.errors['person_id']}
                                           invalid={formik.errors['person_id']}
                                           isSearchable={true}
                                           disabled={disabled}
                                    />
                                </Col>
                            </Row>

                         </fieldset>
                    </Card.Body>
                </Card>
            </Form>
        </React.Fragment>
    )
}
