import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, postRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    CUSTOMER_TYPES,
    ERROR_MESSAGE,
    FINANCING_DOCUMENT_TYPES,
    REQUIRED_FIELDS,
    REQUIRED_FIELDS_TEMP,
    STATUS_ACCEPT,
    STATUS_APPROVE,
    STATUS_NEW,
} from './constant'
import { Area, Check, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { btnMessage, findInSelectData } from '../../../utils/utils'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'
import { MoneyOrders } from '../../../components/databases/financing/money_orders'
import { YearIndicator } from '../../../components/databases/financing/year_indicator'
import { ContractStages } from '../../../components/databases/financing/contract_stages'
import { PaymentProcedure } from '../../../components/databases/financing/payment_procedure'
import { YearResults } from '../../../components/databases/financing/year_results'


const fact_value = {
    assignments: '',
    salary: '',
    salary_sum: 0,
    other: '',
    other_sum: 0,
    full_sum: 0,
}


export const FinancingItem = ({
                                  data,
                                  user,
                                  disabled,
                                  messages,
                                  generateOptions,
                                  ErrorsBlock,
                                  MessageHistory,
                                  ActionButtons,
                                  DisableButton,
                                  HeaderStatus,
                                  setDisabled,
                                  handleSubmit,
                                  setData,
                                  handleSaveAndMove,
                              }) => {
    const params = useParams()
    const navigate = useNavigate()

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})
    const [count_stages, setCountStages] = useState(1)
    const [moneyOrdersData, setMoneyOrder] = useState(null)
    const [moveIndex, setMoveIndex] = useState(1)
    const [errorMessages, setErrorMessages] = useState(null)

    const project_ref = useRef()

    const select_fields = ['project', 'event', 'educational_program']
    const given_select_fields = ['contract_type']


    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'customer': undefined,
        'inn': '',
        'contract_number': '',
        'customer_type': undefined,
        'indicator_type': undefined,
        'contract_type_name': undefined,
        'contract_date': undefined,
        'notes': undefined,
        'person': undefined,
        'report_month': undefined,
        'work_date_start': undefined,
        'work_date_end': undefined,
        'account': null,
        'year_indicator': [],
        'payment_procedure': { single_payment: false, results: [] },
        'orders_ds': [{ id: undefined, date: undefined, number: undefined }],
        'year_result': [{
            year: new Date().getFullYear(),
            result: '',
            accepted: false,
            needAcceptResult: false,
            month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                month: 1,
                results: '',
                accepted: false,
                needAcceptResult: false,
            }, 0, 12),
        }],
        'contract_subject': '',
        'total_cost': '',
        'salary': '',
        'salary_sum': '',
        'other': '',
        'other_sum': '',
        'full_sum': '',
        'actual_income': null,
        'money_orders': '',
        'without_stages': false,
        'contract_stages': [{
            cost: 0,
            date_start: undefined,
            date_end: undefined,
            date_payment: undefined,
            number_payment: undefined,
            is_checked: false,
        }],
        'fact_values': [{ ...fact_value }, { ...fact_value }, { ...fact_value }, { ...fact_value }],
        'is_notes': true,
        'is_contract': true,
        'educational_program': '',
        'is_closed': false,
    })


    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveFunction()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        if (!moneyOrdersData && data?.id) {
            getRequest('financing_orders', setMoneyOrder, { financing: data?.id }).then()
        }
    }, [moneyOrdersData, data])

    useEffect(() => {
        handleSubmit(formik, saveFunction)
    }, [formik.values])


    const setIsEmptyContract = (value) => {
        formik.setFieldValue('is_contract', value)
    }

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД Доход / Софинансирование | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                if (field === 'educational_program') {
                    set_selected['educational_program'] = data['educational_program']?.map((item) => item.id)
                } else {
                    set_selected[`${field}_id`] = data[field]?.id
                }
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            if (!set_inputs?.year_result) {
                set_inputs['year_result'] = [{
                    year: new Date().getFullYear(),
                    result: '',
                    accepted: false,
                    needAcceptResult: false,
                    month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                        month: 1,
                        results: '',
                        accepted: false,
                        needAcceptResult: false,
                    }, 0, 12),
                }]
            }

            if (!set_inputs?.contract_stages) {
                set_inputs['contract_stages'] = [{
                    cost: 0,
                    date_start: undefined,
                    date_end: undefined,
                    is_checked: false,
                }]
            }
            if (!set_inputs?.fact_values) {
                set_inputs['fact_values'] = [{ ...fact_value }, { ...fact_value }, { ...fact_value }, { ...fact_value }]
            }
            if (!set_inputs?.orders_ds) {
                set_inputs['orders_ds'] = input_fields.orders_ds
            }

            formik.setValues({
                ...set_inputs,
                ...set_selected,
                current_status: data.status,
                is_notes: formik?.values?.is_notes,
                is_contract: formik?.values?.is_contract,
            })

            if (data.contract_stages) {
                setCountStages(data.contract_stages?.length)
            }

            if (data.is_closed) {
                setErrorMessages(ERROR_MESSAGE)
            } else {
                setErrorMessages(null)
            }
        }
    }, [data])

    useEffect(() => {
        if ((!userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                && !userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL))
            && data?.status === STATUS_APPROVE) {
            setDisabled(true)
        }
    })

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) && field !== 'event') {
                        if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, {
                                type_data: 'user_projects',
                                'user_id': user.id,
                            }, '', select_data, field)
                        }
                        if (field === 'educational_program' && is_manager) {
                            getRequest('educational_program', setSelectData, { type_data: 'all' }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        }
                        break
                    }

                    if (!select_data?.event?.length && field === 'event' && formik.values?.project_id) {
                        getRequest('event', setSelectData, {
                            type_data: 'all',
                            project: formik.values?.project_id,
                        }, '', select_data, 'event').then((r) => {
                            if (formik.values?.event_id && !findInSelectData(r.data, 'id', formik.values?.event_id)) {
                                setInputFields({ ...formik.values, event_id: null })
                            }
                        })
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (data) {
            const currentStatus = data.status

            if (formik.values.is_closed) {
                setErrorMessages(ERROR_MESSAGE)
                if (currentStatus === STATUS_APPROVE) {
                    setMoveIndex(1)
                }
                if (currentStatus === STATUS_NEW) {
                    setMoveIndex(2)
                }

                return
            }

            setErrorMessages(null)

            if (currentStatus === STATUS_APPROVE) {
                setMoveIndex(-1)
            }
            if (currentStatus === STATUS_NEW) {
                setMoveIndex(1)
            }
        }
    }, [formik.values.is_closed, data])

    const AlertDiv = () => {
        let to_return = <Alert variant={'warning'}>
            Для дальнейшего согласования необходимо прикрепить договор внизу страницы
        </Alert>

        return data && data?.status === STATUS_NEW ? to_return : null
    }

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            setSelectData({ ...select_data, event: [] })
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id
    }, [formik.values])

    const additionalButtons = () => {
        let to_return = []
        if (data?.status === STATUS_APPROVE && userHasPermissions(PERMISSIONS.DIRECTION_PANEL)) {
            to_return.push(<Button key={'save_plus_reject'} variant={'warning'} onClick={() => {
                formik.setFieldValue('without_message', true)
                handleSaveAndMove(-1, formik)
            }}>
                <IconText icon={'redo-alt'}
                          text={'Запрос актов'}/>
            </Button>)
        }
        return to_return
    }

    const saveFunction = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else {
            payload = formik.values
        }
        if (STATUS_NEW === data.status && formik.values.is_closed) {
            payload.status = STATUS_ACCEPT
        }
        if (STATUS_APPROVE === data.status) {
            if (!formik.values.is_closed && (formik.values.status === STATUS_ACCEPT)) {
                payload.status = STATUS_NEW
            }
            if (formik.values.is_closed && (formik.values.status === STATUS_NEW)) {
                payload.status = STATUS_APPROVE
            }
        }

        updateRequest('financing', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                postRequest('recalculate_financing', setData, {
                    financing: data?.id,
                    orders_values: moneyOrdersData,
                }, '', false).then()
                if (payload?.status !== data?.status)
                    navigate('/financing')
                // setData(response.data);
            }
        })
        if (formik?.values?.message) {
            putRequest('financing_messages', {
                content: formik?.values?.message,
                financing_status: data.status,
                status: data.status,
                created_by: user.id,
                financing: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    function generateEduProgramOptions() {
        let options = generateOptions('educational_program', 'event_name', select_data)
        return options ? options?.filter((item) => !!item?.label) : null
    }

    console.log(formik.values.educational_program)

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД Доход / Софинансирование`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_FINANCING}
                                       permission_manage={PERMISSIONS.MANAGE_FINANCING}
                                       additional_buttons={additionalButtons()}
                                       move_index_plus={moveIndex}
                                       can_next={true}
                                       errorMessages={errorMessages}
                                       can_back={data?.status > STATUS_NEW && data?.status !== STATUS_ACCEPT
                                           && !userHasPermissions(PERMISSIONS.IS_MANAGER)}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ width: '200px' }} highlightCurrent={true}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          value={formik.values?.message}
                                          disabled={disabled}
                                          rows='5'
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                    сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>
                                    <Row>
                                        <Col md={4}>
                                            Данные договора
                                        </Col>
                                        <Col md={8}>
                                            <Check
                                                label={'Договор закрыт'}
                                                handleChangeValue={formik.handleChange}
                                                disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ? true : disabled}
                                                name={'is_closed'}
                                                id={'is_closed'}
                                                error={formik.errors.is_closed}
                                                invalid={formik.errors.is_closed}
                                                value={formik.values.is_closed}
                                            />
                                        </Col>
                                    </Row>
                                </legend>

                                <AlertDiv/>
                                <Row>
                                    <Col md={12}>
                                        <Field component={FormikSelect} name={'project_id'}
                                               label={'Проект'}
                                               id={'project_id'}
                                               isClearable={true}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('project') : false}
                                               disabled={disabled}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Исполнитель договора (ФИО)'}
                                               id={'person'}
                                               name={'person'}
                                               error={formik.errors.person}
                                               value={formik.values.person}
                                               invalid={formik.errors.person}
                                               disabled={disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('person')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'customer_type'}
                                               label={'Вид компании заказчика'}
                                               id={'customer_type'}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('customer_type') : false}
                                               isClearable={true}
                                               options={CUSTOMER_TYPES}
                                               error={formik.errors['customer_type']}
                                               invalid={formik.errors['customer_type']}
                                               isSearchable={true}
                                               disabled={disabled}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Заказчик (контрагент)'}
                                               id={'customer'}
                                               name={'customer'}
                                               error={formik.errors.customer}
                                               value={formik.values.customer}
                                               invalid={formik.errors.customer}
                                               disabled={formik.values.customer_type == 2 ? true : disabled}
                                               required_on_transition={REQUIRED_FIELDS[STATUS_NEW].includes('customer')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'ИНН заказчика (контрагента)'} id={'inn'}
                                               name={'inn'}
                                               disabled={disabled}
                                               mask={'999999999999'}
                                               error={formik.errors['inn']}
                                               invalid={formik.errors['inn']}
                                               required_on_transition={REQUIRED_FIELDS[STATUS_NEW].includes('inn')}
                                               value={formik.values ? formik.values?.inn : null}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер договора/номер заказа'}
                                               small={'В случае если договор рамочный, заполнять по шаблону "Договор рамочный [номер договора], заказ [номер заказа]".\n'
                                                   + 'Если договор не является рамочным, то заполнять по шаблону "Заказ [номер заказа]".\n'
                                                   + 'Вместо заказа может быть любой другой вид документа, главное его указать.'}
                                               id={'contract_number'}
                                               name={'contract_number'}
                                               error={formik.errors['contract_number']}
                                               invalid={formik.errors['contract_number']}
                                               disabled={disabled}
                                               value={formik.values.contract_number}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_number')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate} name={'contract_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата договора/заказа'}
                                               small={'Если договор рамочный, то указывается дата заказа. Вместо заказа может быть любой другой вид документа.'}
                                               id={'contract_date'}
                                               error={formik.errors['contract_date']}
                                               disabled={disabled}
                                               invalid={formik.errors['contract_date']}
                                               value={formik.values.contract_date}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_date')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikDate} name={'work_date_end'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата окончания работ'}
                                               id={'work_date_end'}
                                               error={formik.errors['work_date_end']}
                                               invalid={formik.errors['work_date_end']}
                                               disabled={disabled}
                                               value={formik.values.work_date_end}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('work_date_end')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Лицевой счет договора / заказа'}
                                               small={'Если лицевого счета нет, то ввести "-".\n'
                                                   + 'Если договор рамочный, то указывается лицевой счет заказа. \n'
                                                   + 'Вместо заказа может быть любой другой вид документа.'}
                                               id={'account'}
                                               name={'account'}
                                               error={formik.errors['account']}
                                               invalid={formik.errors['account']}
                                               disabled={disabled}
                                               value={formik.values.account}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('account')}
                                        />
                                    </Col>
                                </Row>
                                <YearIndicator formik={formik} yearIndicator={formik.values?.year_indicator}/>
                                <Row>
                                    <Col>
                                        <Field component={FormikSelect}
                                               name={'contract_type'}
                                               label={'Вид договора / заказа'}
                                               small={'Если договор рамочный, то указывать вид заказа. Вместо заказа может быть любой другой документ.'}
                                               isClearable={true}
                                               options={FINANCING_DOCUMENT_TYPES}
                                               id={'contract_type'}
                                               error={formik.errors['contract_type']}
                                               invalid={formik.errors['contract_type']}
                                               disabled={disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_type')}
                                        />
                                    </Col>
                                    {formik.values.contract_type === 9
                                        && <Col>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'Введите вид договора / заказа'}
                                                   id={'contract_type_name'}
                                                   name={'contract_type_name'}
                                                   error={formik.errors['contract_type_name']}
                                                   invalid={formik.errors['contract_type_name']}
                                                   disabled={disabled ? disabled : formik.values.contract_type !== 9}
                                                   value={formik.values.contract_type_name}
                                                   required_on_transition
                                            />
                                        </Col>
                                    }
                                    {formik.values.contract_type === 3
                                        && <Col>
                                            <Field component={FormikSelect}
                                                   label={'Образовательные программы, к которым относится договор'}
                                                   id={'educational_program'}
                                                   name={'educational_program'}
                                                   isMulti={true}
                                                   isClearable={false}
                                                   handleChangeValue={formik.handleChange}
                                                   options={generateEduProgramOptions()}
                                            />
                                        </Col>
                                    }
                                </Row>
                                <Area handleChangeValue={formik.handleChange}
                                      rows='5'
                                      label={'Предмет договора / заказа'}
                                      small={'Если договор рамочный, то указывать предмет заказа'}
                                      id={'contract_subject'}
                                      name={'contract_subject'}
                                      error={formik.errors['contract_subject']}
                                      invalid={formik.errors['contract_subject']}
                                      disabled={disabled}
                                      value={formik.values.contract_subject}
                                      required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_subject')}
                                />

                                <Input label={'Общая сумма договора / заказа'}
                                       type={'number'}
                                       step={'any'}
                                       name={'total_cost'}
                                       min={0}
                                       required={true}
                                       handleChangeValue={formik.handleChange}
                                       error={formik.errors['total_cost']}
                                       invalid={formik.errors['total_cost']}
                                       id={'total_cost'}
                                       value={formik.values.total_cost}
                                       disabled={disabled}/>
                            </fieldset>
                            <fieldset>
                                <ContractStages formik={formik}
                                                count_stages={count_stages}
                                                disabled={disabled}
                                                status={data?.status}
                                                setCountStages={setCountStages}
                                                user={user}/>
                            </fieldset>
                            <fieldset>
                                <PaymentProcedure formik={formik}
                                                  paymentProcedure={formik.values?.payment_procedure}
                                                  status={data?.status}
                                                  disabled={disabled}
                                />
                            </fieldset>
                            <fieldset>
                                <legend>Акт и платежные поручения (при наличии)</legend>
                            </fieldset>
                            <fieldset>
                                <MoneyOrders disabled={disabled} fin_id={data?.id}
                                             isAct
                                             project_id={formik.values?.project_id}
                                             user={user}
                                             setMoneyOrder={setMoneyOrder}
                                             moneyOrdersData={moneyOrdersData}/>
                            </fieldset>
                            <fieldset>
                                <MoneyOrders disabled={disabled} fin_id={data?.id}
                                             user={user}
                                             project_id={formik.values?.project_id}
                                             setMoneyOrder={setMoneyOrder}
                                             moneyOrdersData={moneyOrdersData}/>
                                <Input type={'number'}
                                       label={'Сумма «Платежные поручения»'}
                                       handleChangeValue={formik.handleChange}
                                       disabled={true}
                                       value={formik.values.money_orders}
                                       id={'money_orders'}
                                       name={'money_orders'}
                                />
                                <Input type={'number'}
                                       label={'Сумма «Акты»'}
                                       handleChangeValue={formik.handleChange}
                                       disabled={true}
                                       value={formik.values.actual_income}
                                       id={'actual_income'}
                                       name={'actual_income'}
                                />
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Примечания</legend>
                                <Area id='notes'
                                      name={'notes'}
                                      value={formik.values?.notes}
                                      disabled={disabled}
                                      rows='5'
                                      error={formik.errors['notes']}
                                      invalid={formik.errors['notes']}
                                      handleChangeValue={formik.handleChange}
                                />
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <YearResults disabled={disabled} formik={formik}
                                             yearResults={formik.values.year_result}/>
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                {/*{getAllFilesName()}*/}
                                <Row>
                                    <Col md={6}>
                                        <div className={'form-group required'}>
                                            <Form.Label>Файлы договора и ДС</Form.Label>
                                            <MyFileBrowser
                                                path={`root/storage/financing/financing_contract_${params.id}`}
                                                read_only={disabled}
                                                instanceId={`financing_contract_${params.id}`}
                                                tags={{
                                                    document_type: 'contract',
                                                    year: formik.values?.year_indicator
                                                        ?.map((item) => item?.year)
                                                        ?.join('_'),
                                                    project: formik?.values?.project_id,
                                                    financing_item: data?.id,
                                                    educational_program: formik?.values?.educational_program,
                                                }}
                                                setIsEmpty={setIsEmptyContract}
                                                height={250}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className={'form-group required'}>
                                            <Form.Label>Файлы актов</Form.Label>
                                            <MyFileBrowser
                                                path={`root/storage/financing/financing_acts_${params.id}`}
                                                read_only={disabled}
                                                instanceId={`financing_acts_${params.id}`}
                                                height={250}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className={'form-group required'}>
                                            <Form.Label>Файлы платежных поручений</Form.Label>
                                            <MyFileBrowser
                                                path={`root/storage/financing/financing_orders_${params.id}`}
                                                read_only={disabled}
                                                instanceId={`financing_orders_${params.id}`}
                                                height={250}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <hr/>
                                <MyFileBrowser path={`root/storage/financing/financing_${params.id}`}
                                               read_only={data?.status > STATUS_NEW && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                   ? true : disabled}
                                               instanceId={`financing_${params.id}`}/>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )

}
