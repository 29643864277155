import * as React from 'react'
import { Button, Card } from 'react-bootstrap'
import { getRequest, updateRequest } from '../../actions/common'
import ReactBreadcrumb from '../../components/breadcrumbs'
import { Check, Input, Area } from '../../components/form/forms'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../../components/form/formik'


const UserUpdate = () => {
    const params = useParams()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            middle_name: '',
            username: '',
            email: '',
            project: '',
            roles: [],
            projects: [],
            description: '',
            is_active: 'on',
        },
        onSubmit: (values) => onSubmitForm(values),
        validateOnBlur: false,
        validateOnChange: false,
    })

    const [allProjects, setAllProjects] = useState(null)
    const [user, setUser] = useState(null)

    React.useEffect(() => {
        document.title = 'Редактировать | ИС «ПИШ»'
    })

    useEffect(() => {
        if (!allProjects) {
            getRequest('project', setAllProjects, { type_data: 'all' })
        }
    }, [allProjects])

    useEffect(() => {
        if (!user) {
            getRequest('users', setUser, {}, params.id, null, null, true)
        } else {
            let new_values = {}
            for (let key of Object.keys(formik.values)) {
                if (key === 'project') {
                    new_values[key] = user[key]?.id
                } else if (key === 'projects') {
                    new_values[key] = user[key]?.map((element) => element.id)
                } else if (key === 'is_active') {
                    new_values[key] = user[key] ? 'on' : 'off'
                } else {
                    new_values[key] = user[key]
                }
            }
            formik.setValues(new_values)
        }
    }, [user])

    const onSubmitForm = (values) => {
        let data = { data: { ...values } }
        data.data['is_active'] = values.is_active === 'on'

        updateRequest('user', { 'common': data }, params.id).then((response) => {
            if (response.status === 200) {
                navigate('/users/')
            }
        })
    }

    const generateOptions = (id) => {
        if (allProjects) {
            let options = []
            allProjects.forEach((element) => {
                options.push({ value: element.id, label: element.title_short })
            })
            return options
        }
        return null
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Редактировать
            </h1>
            <FormikProvider value={formik}>
                <form>
                    <Card>
                        <Card.Body>
                            <Input label={'Фамилия'} id={'last_name'} name={'last_name'} required={true}
                                   value={formik ? formik.values?.last_name : null}
                                   handleChangeValue={formik.handleChange}
                                   invalid={formik.errors?.last_name}
                                   error={formik.errors?.last_name}
                            />
                            <Input label={'Имя'} id={'first_name'} name={'first_name'} required={true}
                                   value={formik ? formik.values?.first_name : null}
                                   handleChangeValue={formik.handleChange}
                                   invalid={formik.errors?.first_name}
                                   error={formik.errors?.first_name}
                            />
                            <Input label={'Отчество'} id={'middle_name'} name={'middle_name'}
                                   value={formik ? formik.values?.middle_name : null}
                                   handleChangeValue={formik.handleChange}
                            />
                            <Input label={'Логин'} id={'username'} name={'username'}
                                   value={formik ? formik.values?.username : null}
                                   handleChangeValue={formik.handleChange}
                            />
                            <Input label={'Email адрес'} required={true} id={'email'} name={'email'}
                                   value={formik ? formik.values?.email : null}
                                   handleChangeValue={formik.handleChange}
                                   invalid={formik.errors?.email}
                                   error={formik.errors?.email}
                            />
                            <Field component={FormikSelect}
                                   label={'Роли'} id={'roles'}
                                   name={'roles'}
                                   isMulti={true}
                                   isClearable={false}
                                   handleChangeValue={formik.handleChange}
                                   options={[
                                       { value: 'project_manager', label: 'Менеджер проекта' },
                                       { value: 'direction_financing', label: 'Дирекция софин' },
                                       { value: 'direction_staff', label: 'Дирекция кадры' },
                                       { value: 'direction_edu', label: 'Дирекция образование' },
                                       { value: 'direction_rid', label: 'Дирекция РИД' },
                                       { value: 'direction_oop', label: 'Дирекция ООП' },
                                       { value: 'direction_dpo', label: 'Дирекция ДПО' },
                                       { value: 'direction', label: 'Дирекция' },
                                       { value: 'admin', label: 'Администратор' },
                                       { value: 'management', label: 'Руководители' },
                                       { value: 'inspector', label: 'Инспектор' },
                                   ]}
                            />
                            <Field component={FormikSelect}
                                   label={'Проекты'} id={'projects'}
                                   name={'projects'}
                                   isMulti={true}
                                   isClearable={false}
                                   handleChangeValue={formik.handleChange}
                                   options={generateOptions()}
                            />
                            <Area label={'Описание'} id={'description'}
                                  value={formik ? formik.values?.description : null}
                            />
                            <Field component={FormikSelect}
                                   label={'Доступ'} id={'is_active'}
                                   name={'is_active'}
                                   isClearable={false}
                                   handleChangeValue={formik.handleChange}
                                   options={[
                                       { value: 'off', label: 'Закрыт' },
                                       { value: 'on', label: 'Открыт' },
                                   ]}
                            />
                        </Card.Body>
                        <Card.Footer>
                            <div className={'button-actions'}>
                                <Button variant='success' onClick={formik.submitForm}>Сохранить</Button>
                                <Button variant='secondary' onClick={() => {
                                    window.location.reload()
                                }}>Сбросить</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </form>
            </FormikProvider>
        </React.Fragment>
    )
}

export default UserUpdate
