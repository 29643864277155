import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Table } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import { deleteRequest, getRequest } from '../../../actions/common'
import { useNavigate, useParams } from 'react-router-dom'
import { IconText } from '../../../components/icon_txt'
import { ROLES } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { AuthContext } from '../../../auth'

const IndicatorField = () => {
    const [indicator, setIndicator] = useState(null)
    const params = useParams()
    const navigate = useNavigate()
    const user = useContext(AuthContext)

    const association = {
        id: 'ID',
        unit: 'Единица измерения',
        description: 'Описание',
        value: 'Значение',
        value_plan: 'Ожидается',
        value_passport: 'Паспорт',
        title: 'Заголовок',
        title_short: 'Сокращенное наименование',
    }

    useEffect(() => {
        if (!indicator) {
            getRequest('indicator', setIndicator, {}, params.id)
        } else {
            document.title = `${indicator.results[0].title_short} | ИС «ПИШ»`
        }
    }, [indicator])

    const CheckField = ({ field }) => {
        if (indicator) {
            if (indicator.results[0][field]) {
                return <span>{indicator.results[0][field]}</span>
            } else {
                return <span className={'not-set'}>(не задано)</span>
            }
        }
    }

    const TablesRow = () => {
        if (indicator) {
            let rows = []
            for (let key in indicator.results[0]) {
                if (key in association) {
                    rows.push(
                        <tr key={key}>
                            <th className={'text-right'}>{association[key]}</th>
                            <td className={'text-left'}><CheckField field={key}/></td>
                        </tr>,
                    )
                }
            }
            return rows
        }
        return null
    }

    const handleDelete = () => {
        if (indicator !== null) {
            deleteRequest('indicator', indicator.results[0].id)
            navigate('/indicator/')
        }
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                {indicator !== null ? indicator.results[0].title_short : null}
            </h1>
            {!user?.roles?.includes(ROLES.management)
                ? <div className='button-actions mb-4'>
                    <Button onClick={() => {
                        navigate(`/indicator/update/${params.id}`)
                    }}>
                        <IconText icon={'pencil-alt'} text={'Редактировать'}/>
                    </Button>
                    <Button variant='danger' onClick={handleDelete}>
                        <IconText icon={'trash-alt'} text={'Удалить'}/>
                    </Button>
                </div> : null}
            <Table striped bordered hover>
                <tbody>
                <TablesRow/>
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default IndicatorField
