import * as React from 'react'
import { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { REPORT_YEAR } from '../../../screens/database/rid/constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, Formik } from 'formik'
import { IconText } from '../../icon_txt'
import { toast } from 'react-toastify'
import { FormikSelect } from '../../form/formik'
import { Area } from '../../form/forms'

export const CurrentResults = ({ formik, currentResults }) => {
    const [showInfo, setShowInfo] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const handleAddYear = (values) => {
        if (formik.values?.current_results?.find((item) => item?.year === Number(values?.year))) {
            toast.error('Введенный год уже присутствует в результатах по годам')
            return
        }

        const previousCurrentResults = formik.values.current_results ? formik.values.current_results : []

        formik.setFieldValue('current_results', [...previousCurrentResults, {
            year: values?.year, result: '',
        }])

        setShowModal(false)
    }

    return (<>
        <Row className={'align-items-center mb-3'}>
            <Col md={2}>
                <h5 className={'m-0'}>Результаты по годам</h5>
            </Col>
            <Col>
                <Button variant={'primary'}
                        onClick={() => setShowInfo(!showInfo)}>
                    {showInfo ? 'Скрыть' : 'Показать'}
                </Button>
            </Col>
        </Row>
        {showInfo ? (<>
            <Modal size={'md'} show={showModal} centered={true} onHide={() => setShowModal(false)}>
                <div className={'modal-content'}>
                    <Modal.Header>
                        <Modal.Title>
                            Добавление результата за год
                        </Modal.Title>
                        <button className={'close'} onClick={() => {
                            setShowModal(false)
                        }}><FontAwesomeIcon icon={'times'}/>
                        </button>
                    </Modal.Header>
                    <Formik
                        initialValues={{ year: '' }}
                        onSubmit={(values) => handleAddYear(values)}>
                        {(props) => (<Form>
                            <Modal.Body>
                                <Field component={FormikSelect}
                                       name={'year'}
                                       label={'Год'}
                                       options={REPORT_YEAR}
                                       id={'year'}
                                       error={props.errors['year']}
                                       invalid={props.errors['year']}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <div className={'button-actions'}>
                                    <Button variant={'success'}
                                            onClick={() => props.submitForm()}>
                                        <IconText icon={'plus'} text={'Создать'}/>
                                    </Button>
                                    <Button variant={'warning'} onClick={() => {
                                        setShowModal(false)
                                    }}>
                                        Отменить
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>)}
                    </Formik>
                </div>
            </Modal>
            <Button className={'mb-4'} variant={'success'} onClick={() => setShowModal(true)}>
                <IconText text={'Добавить год'} icon={'plus'}/>
            </Button>
            {currentResults?.length
                ? currentResults.map((result, index) => (
                    <Area
                        key={result?.year}
                        id={`current_results.${index}.result`}
                        name={`current_results.${index}.result`}
                        label={`Результаты за ${result?.year}`}
                        value={result?.result}
                        rows='5'
                        handleChangeValue={formik.handleChange}
                    />
                ))
                : null}
        </>) : null}
    </>)
}
