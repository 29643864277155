import { Field, FieldArray } from 'formik'
import { Check, Input } from '../../form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { CAN_MANAGE_ROLES, STATUS_APPROVE, STATUS_NEW } from '../../../screens/database/financing/constant'
import * as React from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap'
import { FormikDate } from '../../form/formik'
import { useAbac } from 'react-abac'

export const ContractStages = ({ formik, count_stages, setCountStages, user, disabled, status }) => {
    const { userHasPermissions } = useAbac()

    return <>
        <legend>Этапы договора</legend>
        <FieldArray name={'contract_stages'}
                    render={(arrayHelpers) => (<div>
                        <Check label={'Без этапов'}
                               name={'without_stages'}
                               id={'without_stages'}
                               value={formik.values.without_stages}
                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && status > STATUS_NEW || disabled}
                               handleChangeValue={(event) => {
                                   if (event.target.checked) {
                                       setCountStages(1)
                                       for (let index = count_stages; index > 0; index--) {
                                           arrayHelpers.pop()
                                       }
                                   }
                                   formik.handleChange(event)
                               }}
                        />
                        {
                            !(!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && status > STATUS_NEW)
                                ? !formik.values.without_stages && !disabled ? <React.Fragment>
                                        <div className={'mb-4'}>
                                            <Button variant={'success'} onClick={() => {
                                                setCountStages(count_stages + 1)
                                                arrayHelpers.push({
                                                    cost: 0,
                                                    date_start: undefined,
                                                    date_end: undefined,
                                                    is_checked: false,
                                                })
                                            }}>Добавить этап</Button>
                                            <Button variant={'danger'} onClick={() => {
                                                if (count_stages > 1) {
                                                    setCountStages(count_stages - 1)
                                                    arrayHelpers.pop()
                                                }
                                            }} className={'ml-2'}>Удалить этап</Button>
                                        </div>
                                    </React.Fragment>
                                    : null : null
                        }
                        {arrayHelpers.form.values?.contract_stages?.map((stage, index) => (
                            <div key={`stage_card_${index}`}>
                                <div className={'d-flex flex-row'}>
                                    <div>
                                        <p>Этап {index + 1}</p>
                                    </div>
                                    <div>
                                        {stage?.is_checked
                                            ? <Badge bg={'success'}
                                                     className={'ml-2'}
                                                     style={{ color: 'white' }}>
                                                Согласован
                                            </Badge>
                                            : null}
                                    </div>
                                </div>
                                <Row>
                                    <Col>
                                        <Field component={FormikDate}
                                               name={`contract_stages.${index}.date_start`}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата начала'}
                                               id={'date_start'}
                                               disabled={
                                                   !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                   && (status > STATUS_NEW || stage?.is_checked)
                                                       ? true : disabled
                                               }
                                               value={stage.date_start}
                                               required={true}
                                               error={arrayHelpers.form.errors?.contract_stages?.[index]?.date_start}
                                               invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.date_start}
                                        />
                                    </Col>
                                    <Col>
                                        <Field component={FormikDate}
                                               name={`contract_stages.${index}.date_end`}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата окончания'}
                                               id={'date_end'}
                                               disabled={
                                                   !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                   && (status > STATUS_NEW || stage?.is_checked)
                                                       ? true : disabled
                                               }
                                               value={stage.date_end}
                                               required={true}
                                               error={arrayHelpers.form.errors?.contract_stages?.[index]?.date_end}
                                               invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.date_end}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Input label={'Сумма этапа'}
                                               type={'number'}
                                               step={'any'}
                                               name={`contract_stages.${index}.sum`}
                                               min={0}
                                               required={true}
                                               handleChangeValue={formik.handleChange}
                                               id={`contract_stages.${index}.sum`}
                                               value={stage.sum}
                                               error={arrayHelpers.form.errors?.contract_stages?.[index]?.sum}
                                               invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.sum}
                                               disabled={disabled}/>
                                    </Col>
                                    <Col>
                                        <Input name={`contract_stages.${index}.year`}
                                               id={`contract_stages.${index}.year`}
                                               label={'Год зачета этапа'}
                                               max={2033}
                                               min={2021}
                                               required={true}
                                               type={'year'}
                                               value={stage.year}
                                               disabled={disabled}
                                               error={arrayHelpers.form.errors?.contract_stages?.[index]?.year}
                                               invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.year}
                                               handleChangeValue={formik.handleChange}
                                        />
                                    </Col>
                                </Row>
                                {user.roles.some((role) => CAN_MANAGE_ROLES[STATUS_APPROVE].includes(role))
                                    ? <Check
                                        label={'Проверено'}
                                        handleChangeValue={formik.handleChange}
                                        disabled={
                                            !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                            && (status > STATUS_NEW || stage?.is_checked)
                                                ? true : disabled
                                        }
                                        name={`contract_stages.${index}.is_checked`}
                                        id={`contract_stages.${index}.is_checked`}
                                        error={arrayHelpers.form.errors?.contract_stages?.[index]?.is_checked}
                                        invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.is_checked}
                                        value={stage?.is_checked}
                                    />
                                    : null}
                                <hr/>

                            </div>
                        ))}
                    </div>)}
        />
    </>
}
