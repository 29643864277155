import * as React from 'react'
import ReactBreadcrumb from '../../../../components/breadcrumbs'
import { useEffect, useState } from 'react'
import {
    Button,
    Card,
    Col,
    Row,
    Form,
} from 'react-bootstrap'
import { IconText } from '../../../../components/icon_txt'
import { getRequest, putRequest } from '../../../../actions/common'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../../rbac/constant'
import { Input } from '../../../../components/form/forms'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { MONTHS, REPORT_YEAR } from '../constant'
import { RID_TYPES } from './constant'
import { FormikSelect } from '../../../../components/form/formik'
import { toast } from 'react-toastify'


const RidExternal = () => {
    const today = new Date()
    const navigate = useNavigate()
    const { userHasPermissions } = useAbac()
    const formik = useFormik({
        initialValues: {
            results: null,
            year: today.getFullYear(),
            month: today.getMonth() + 1,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            return saveRidExternal()
        },
    })
    const [data, setData] = useState(null)
    const [totalCurrent, setTotalCurrent] = useState(0)
    const [totalAccumulated, setTotalAccumulated] = useState(0)
    const [currentMonth, setCurrentMonth] = useState(today.getMonth() + 1)
    const [currentYear, setCurrentYear] = useState(today.getFullYear())
    const [not2021, setNot2021] = useState(false)
    const [colMdValue, setColMdValue] = useState(4)
    const [reset, setReset] = useState(false)

    useEffect(() => {
        document.title = 'РИДы (внешний мониторинг) | ИС «ПИШ»'
    })

    const sortResults = (results, sortOrder) => {
        let mapped = {} 
        sortOrder.map((el, index) => {mapped[el] = index})
        
        let sortedResults = results
        sortedResults.sort((a, b) => {
            return (mapped[a.title] - mapped[b.title])
        })
        return sortedResults
    }        

    useEffect(() => {
        let isNewMonth = (currentMonth !== formik.values.month)
        let isNewYear = (currentYear !== formik.values.year)
        if (!data || isNewMonth || isNewYear || reset) {
            getRequest('rid_external', setData, { year: formik.values.year, month: formik.values.month }, '', data).then((response) => {
                if (response?.status === 200) {
                    setData(response.data)
                }
            })
        }
        setCurrentMonth(formik.values.month)
        setCurrentYear(formik.values.year)
        if (formik.values.year === 2021) {
            setNot2021(false)
            setColMdValue(6)
            formik.values.month = 1
        } else {
            setNot2021(true)
            setColMdValue(4)
        } 
        setReset(false)
    }, [data, formik.values, currentMonth, currentYear, reset])

    useEffect(() => {
        if (data) {
            let setSelected = { 'month': formik.values.month, 'year': formik.values.year }
            let results = data?.results
            results.forEach((res) => {
                res['result_accumulated'] = Number(res['result_accumulated']).toFixed(0)
                res['result_current'] = Number(res['result_current']).toFixed(0)
            })
            results = sortResults(results, RID_TYPES)
            setTotalCurrent(Number(data['total_current']).toFixed(0))
            setTotalAccumulated(Number(data['total_accumulated']).toFixed(0))

            formik.setValues({ 'results': results, ...setSelected })
        }
    }, [data])

    const saveRidExternal = () => {
        putRequest('rid_external', { 'common': formik.values }, '')
            .then((response) => {
                if (response.status === 201) {
                    toast.success('Данные сохранены')
                    getRequest('rid_external', setData, { year: formik.values.year, month: formik.values.month }, '', data)
                        .then((response) => {
                            if (response?.status === 200) {
                                setData(response.data)
                            }
                        })
                }
            },
        )
        return Promise.resolve('is_saved')
    }

   
    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <div className='section-header sticky-top'>         
                <h1 className={'page-header'}>
                    РИДы (внешний мониторинг)
                </h1>
                <div className='button-actions mb-3'>
                    <Button variant={'info'} disabled={true} className={'mb-3'} onClick={() => {
                            navigate('/rid/rid-external')
                        }}>
                        <IconText icon={'dot-circle'} text={'Внешний мониторинг'}/>
                    </Button> 
                    <Button variant={'info'} className={'mb-3'} onClick={() => {
                            navigate('/rid/rid-internal') 
                        }}>
                        <IconText icon={'circle'} text={'Внутренний мониторинг'}/>
                    </Button> 
                    {userHasPermissions(PERMISSIONS.MANAGE_COMMON)    
                    ? <div className='button-actions mb-3'>
                        <Button variant={'success'} onClick={() => saveRidExternal()}>Сохранить</Button>
                        <Button key={'reset'} variant={'secondary'} onClick={() => setReset(true)}>
                            <IconText icon={'ban'} text={'Сбросить'}/>
                        </Button>
                        </div>
                    : null}
                </div>
            </div>
            
            <fieldset>
                <FormikProvider value={formik}>
                    <Form>
                        <Card bg={'light'}>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                    { not2021 
                                      &&  <Field component={FormikSelect}
                                            name={'month'} label={'Месяц зачета'}
                                            id={'month'}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={MONTHS.map((v, i) => {
                                                return { value: i + 1, label: v }
                                            })}
                                            error={formik.errors['month_id']}
                                            invalid={formik.errors['month_id']}
                                        />
                                    }
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                            name={'year'} label={'Год зачета'}
                                            id={'year'}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={REPORT_YEAR}
                                            error={formik.errors['year_id']}
                                            invalid={formik.errors['year_id']}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={colMdValue} className='d-flex justify-content-center align-items-center'>
                                        <div className={'lead mb-0'}> <legend>Вид</legend> </div>
                                    </Col>
                                    { not2021 
                                        && <Col md={colMdValue}>
                                                <legend>
                                                    Накопительный с начала программы, шт
                                                </legend>
                                           </Col>
                                    }
                                    <Col md={colMdValue}>
                                        { not2021 
                                           ? <legend>
                                                Накопительный с начала выбранного года на
                                                момент выбранного месяца, шт
                                             </legend> 
                                           : <legend>
                                                На момент начала программы, шт
                                             </legend>
                                        } 
                                    </Col>
                                </Row>

                                <FieldArray name={'results'} render={(arrayHelpers) => (<div>
                                    {arrayHelpers.form.values?.results?.map((res, index) => (
                                        <fieldset key={index}>
                                            <Row>
                                                <Col md={colMdValue} className='d-flex justify-content-center align-items-center'>
                                                    <div className={'lead mb-0'}>{res.title}</div>
                                                </Col>
                                                { not2021 
                                                    && <Col md={colMdValue}>
                                                           <Input
                                                               id={`results.${index}.result_accumulated`}
                                                               name={`results.${index}.result_accumulated`}
                                                               type={'int'}
                                                               handleChangeValue={formik.handleChange}
                                                               value={res.result_accumulated}
                                                               error={formik.errors['results']}
                                                               invalid={formik.errors['results']}
                                                               disabled={true}
                                                           />
                                                        </Col>
                                                }
                                                <Col md={colMdValue}>
                                                    <Input
                                                        id={`results.${index}.result_current`}
                                                        name={`results.${index}.result_current`}
                                                        handleChangeValue={formik.handleChange}
                                                        type={'int'}
                                                        value={res.result_current}
                                                        error={formik.errors['results']}
                                                        invalid={formik.errors['results']}
                                                    />
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    ))}
                                </div>)}/>
                                
                                <Row>
                                    <Col md={colMdValue} className='d-flex justify-content-center align-items-center'>
                                        <div className={'lead mb-0'}> <legend>Итого:</legend> </div>
                                    </Col>
                                    { not2021 
                                        && <Col md={colMdValue}>
                                                <legend>
                                                    {totalAccumulated}
                                                </legend>
                                            </Col>
                                    }   
                                    <Col md={colMdValue}>
                                        <legend>
                                            {totalCurrent}
                                        </legend>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Form>
                </FormikProvider>
            </fieldset>
           
            
        </React.Fragment>
    )
}

export default RidExternal
