import * as React from 'react'
import { Button, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteRequest, getRequest, updateRequest } from '../../actions/common'
import ReactBreadcrumb from '../../components/breadcrumbs'
import { IconText } from '../../components/icon_txt'
import { ROLES_LABELS } from '../../rbac/constant'
import { setAuthToken } from '../../actions/auth'
import axiosInstance, { protectedAxios } from '../../utils/axiosAPI'


const UserField = () => {
    const [data, setData] = useState(null)
    const [tokens, setTokens] = useState(null)
    const params = useParams()
    const navigate = useNavigate()

    const fields = ['id', 'username', 'email', 'fio', 'projects', 'roles', 'description',
        'is_active', 'status', 'personal_access_token', 'verify_token', 'time_email_verified',
        'data_joined', 'last_login', 'ip_create', 'ip_last']
    const labels = {
        'id': 'ID',
        'username': 'Логин',
        'email': 'Email адрес',
        'fio': 'ФИО',
        'projects': 'Проекты',
        'roles': 'Роли',
        'description': 'Описание',
        'is_active': 'Доступ',
        'status': 'Статус',
        'personal_access_token': 'Personal Access Token',
        'verify_token': 'Токен для подтверждения email',
        'time_email_verified': 'Время верификации email',
        'data_joined': 'Время создания',
        'last_login': 'Время последнего визита',
        'ip_create': 'IP при создании',
        'ip_last': 'IP последнего визита',
    }

    useEffect(() => {
        if (!data) {
            getRequest('users', setData, {}, params.id)
        } else {
            document.title = `${data.results[0].fio} | ИС «ПИШ»`
        }
    }, [data])

    useEffect(() => {
        if (tokens) {
            protectedAxios(axiosInstance.post, '/api/logout/',
                { refresh_token: localStorage.getItem('refresh_token') }).then((response) => {
                if (response.status === 205) {
                    localStorage.setItem('token', tokens.access)
                    localStorage.setItem('refresh_token', tokens.refresh)

                    setAuthToken(tokens.access)
                    window.location.href = '/'
                }
            })
        }
    }, [tokens])

    const CheckField = ({ name, field }) => {
        if (name === 'status') {
            if (field === 1) {
                return <span className='badge badge-success'>Зарегистрированный</span>
            }
        }
        if (name === 'is_active') {
            if (field === true) {
                return <span className='badge badge-success'>Открыт</span>
            } else if (field === false) {
                return <span className='badge badge-danger'>Закрыт</span>
            }
        }
        if (name === 'projects' && field !== null) {
            let elements = []
            field.forEach((project) => {
                elements.push(<>
                    <span key={project} className={'badge badge-secondary'}>
                        {project['title_short']}
                    </span>
                    &nbsp;
                </>)
            })

            return elements
        }
        if (name === 'roles') {
            let elements = []
            field.forEach((role) => {
                elements.push(<span key={role}
                                    className={`badge ${role === 'admin' ? 'badge-danger' : 'badge-secondary'}`}>{ROLES_LABELS[role]}</span>)
            })
            return elements
        }
        if (field) {
            return <span>{field}</span>
        } else {
            return <span className={'not-set'}>(не задано)</span>
        }
    }

    const TablesRow = () => {
        if (data) {
            let rows = []
            for (let key in data.results[0]) {
                if (fields.includes(key)) {
                    rows.push(
                        <tr key={key} className={'detail-view'}>
                            <th className={'text-right'}>{labels[key]}</th>
                            <td className={'text-left'}><CheckField name={key} field={data.results[0][key]}/>
                            </td>
                        </tr>,
                    )
                }
            }
            return rows
        }
        return null
    }

    const handleDelete = () => {
        if (data !== null) {
            deleteRequest('users', data.results[0].id).then()
            navigate('/users/')
        }
    }

    const handleLoginAsUser = (e) => {
        e.preventDefault()
        getRequest('login_as_user', setTokens, { user_id: data.results[0].id })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                {data !== null ? data.results[0].fio : null}
            </h1>
            <div className='button-actions mb-4'>
                <Button variant={'warning'} onClick={() => {
                    navigate(`/users/update/${params.id}`)
                }}>
                    <IconText icon={'pencil-alt'} text={'Редактировать'}/>
                </Button>
                {data && data.results[0].is_active
                    ? <Button variant='secondary' onClick={handleLoginAsUser}>
                        <IconText icon={'sign-in-alt'} text={'Войти под пользователем'}/>
                    </Button>
                    : null}
                <Button variant='secondary'>
                    <IconText icon={'life-ring'} text={'Генерация персонального токена'}/>
                </Button>
                <Button variant='danger' onClick={handleDelete}>
                    <IconText icon={'trash-alt'} text={'Удалить'}/>
                </Button>
            </div>
            <Table striped bordered hover size={'sm'}>
                <tbody>
                <TablesRow/>
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default UserField
