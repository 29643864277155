import * as React from 'react'
import {Col, Dropdown} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconText} from '../../icon_txt'
import {downloadPage} from '../../../utils/utils'
import {useState} from 'react'
import {putRequest, downloadRequest} from '../../../actions/common'
import {Select} from '../../form/forms'

export const Export = ({getTitle}) => (
    <Dropdown className={'mr-1 d-inline-block'}>
        <Dropdown.Toggle variant={'outline-secondary'}>
            <FontAwesomeIcon icon={'external-link-alt'}/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Header>
                Экспорт данных с текущей страницы
            </Dropdown.Header>
            <Dropdown.Item onClick={() =>
                downloadPage('xlsx', 'tblToExcl', getTitle, true)
            }>
                <IconText text={'Excel'} icon={'file-excel'} icon_color={'green'}/>
            </Dropdown.Item>
            <Dropdown.Item onClick={() =>
                downloadPage('csv', 'tblToExcl', getTitle, true)
            }>
                <IconText text={'CSV'} icon={'file-csv'} icon_color={'#007bff'}/>
            </Dropdown.Item>
            <Dropdown.Item onClick={() =>
                downloadPage('json', 'tblToExcl', getTitle, true)}
                           disabled={true}
            >
                <IconText text={'JSON'} icon={'file-code'} icon_color={'#ffc107'}/>
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
)

export const ExportNiokr = ({getTitle, urlExport}) => {
    const [exportYear, setExportYear] = useState(new Date().getFullYear())
    const YEAR = [
        {value: 2022, label: 2022},
        {value: 2023, label: 2023},
        {value: 2024, label: 2024},
        {value: 2025, label: 2025},
        {value: 2026, label: 2026},
        {value: 2027, label: 2027},
        {value: 2028, label: 2028},
        {value: 2029, label: 2029},
        {value: 2030, label: 2030},
    ]

    const customDownload = () => {
        putRequest(urlExport, exportYear.year).then((response) => {
            if (response.status === 200) {
                downloadRequest(getTitle,
                    {file_name: `${getTitle}_export/${response.data}`})
            }
        })
    }

    return (
        <Dropdown className={'mr-1 d-inline-block'} autoClose="outside">
            <Dropdown.Toggle variant={'outline-secondary'}>
                <FontAwesomeIcon icon={'external-link-alt'}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>
                    Экспорт данных с текущей страницы
                </Dropdown.Header>
                <Dropdown.Item href={''}>
                    1. Введите год, за который необходимо выгрузить данные:
                    <div>
                        <Select id={'year'}
                                isSearchable={true}
                                isClearable={true}
                                options={YEAR}
                                select_values={exportYear}
                                setSelectValues={setExportYear}/>
                    </div>
                </Dropdown.Item>
                <Dropdown.Item>
                    2. Нажмите на нужный формат:
                </Dropdown.Item>
                <Dropdown.Item onClick={() => customDownload()}>
                    <IconText text={'Excel'} icon={'file-excel'} icon_color={'green'}/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() =>
                    downloadPage('csv', 'tblToExcl', getTitle, true)
                }>
                    <IconText text={'CSV'} icon={'file-csv'} icon_color={'#007bff'}/>
                </Dropdown.Item>
                <Dropdown.Item onClick={() =>
                    downloadPage('json', 'tblToExcl', getTitle, true)}
                               disabled={true}
                >
                    <IconText text={'JSON'} icon={'file-code'} icon_color={'#ffc107'}/>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
