import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Field, FormikProvider, useFormik } from 'formik'
import { Button, Col, Dropdown, Row, Table } from 'react-bootstrap'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { getRequest, updateRequest } from '../../../actions/common'
import { FormikSelect } from '../../../components/form/formik'
import { REPORT_YEAR } from './constant'
import { IconText } from '../../../components/icon_txt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadPage } from '../../../utils/utils'
import { MONTHS } from '../../database/niokr/constant'
import { Input } from '../../../components/form/forms'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../rbac/constant'
import { AuthContext } from '../../../auth'

const GrantProject = () => {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { userHasPermissions } = useAbac()
    const user = useContext(AuthContext)

    const [projects, setProjects] = useState()
    const [grant, setGrant] = useState()

    const filterFormik = useFormik({
        initialValues: {
            year: searchParams.get('year') ? Number(searchParams.get('year')) : new Date().getFullYear(),
            project: params?.id && params?.id !== 'null' ? Number(params?.id) : null,
        },
    })

    const valuesFormik = useFormik({
        initialValues: {
            plan: {
                wage: 0,
                procurement: 0,
                business_trips: 0,
            },
            fact: {
                wage: 0,
                procurement: 0,
                business_trips: 0,
            },
            total: {
                plan: 0,
                fact: 0,
                remainder: 0,
            },
            remainder: {
                wage: 0,
                procurement: 0,
                business_trips: 0,
            },
            months: {
                [1]: 0,
                [2]: 0,
                [3]: 0,
                [4]: 0,
                [5]: 0,
                [6]: 0,
                [7]: 0,
                [8]: 0,
                [9]: 0,
                [10]: 0,
                [11]: 0,
                [12]: 0,
            },
        },
        onSubmit: (values) => {
            updateRequest('grant_db', { common: values }, grant?.id).then((r) => {
                if (r?.data) setGrant(r?.data)
            })
        },
    })

    useEffect(() => {
        getRequest('project', setProjects, { user_id: user?.id }).then()
    }, [])

    useEffect(() => {
        valuesFormik.resetForm()
        getRequest('grant_db_request', setGrant, {
            type_data: 'all',
            year: filterFormik.values.year,
            project: filterFormik.values.project,
        }).then()
    }, [filterFormik.values])

    useEffect(() => {
        setBackGrant()
    }, [grant])


    const setBackGrant = () => {
        let toSet = {}
        for (let field of ['plan', 'fact', 'months', 'total', 'remainder']) {
            toSet[field] = grant?.[field] ? grant?.[field] : valuesFormik.initialValues?.[field]
        }
        valuesFormik.setValues({ ...valuesFormik.values, ...toSet })
    }

    const getLabel = () => {
        if (params?.id === 'null') {
            return 'Инфраструктурные расходы'
        }
        return projects ? projects?.find((item) => item?.id === filterFormik.values.project)?.title_short : ''
    }

    const projectOptions = () => {
        if (!projects) {
            return null
        }
        
        let options = projects?.map((item) => {
            return {
                value: item?.id,
                label: item?.title_short,
            }
        })

        if (userHasPermissions(PERMISSIONS.IS_MANAGER)) {
            return options
        }

        return options.concat([{
            value: null,
            label: 'Инфраструктурные расходы',
        }])
    }

    return <>
        <ReactBreadcrumb
            label={getLabel()}/>
        <h1 className={'page-header text-center'}>
            Смета проекта
            <h5 className={'text-secondary'}>
                В таблице указан план на текущий год. Указанные фактические расходы получены
                нарастающим итогом на текущий момент времени.
            </h5>
        </h1>
        <div className={'d-flex justify-content-between align-items-center'}>
            <FormikProvider value={filterFormik}>
                <Row className={'w-25'}>
                    <Col>
                        <Field component={FormikSelect} label={'Проект'}
                               id={'project'}
                               name={'project'}
                               disabled={false}
                               isClearable={false}
                               handleChangeValue={filterFormik.handleChange}
                               options={projectOptions()}
                        />
                    </Col>
                    <Col>
                        <Field component={FormikSelect} label={'Год'}
                               id={'year'}
                               name={'year'}
                               isClearable={false}
                               handleChangeValue={filterFormik.handleChange}
                               options={REPORT_YEAR}/>
                    </Col>
                </Row>
                <div>
                    {userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ? <>
                        <Button variant={'success'} className={'mr-2'} onClick={() => valuesFormik.submitForm()}>
                            Сохранить
                        </Button>

                        <Button variant={'secondary'} className={'mr-2'} onClick={() => setBackGrant()}>
                            <IconText icon={'ban'}
                                      text={'Отмена'}/>
                        </Button>
                    </> : null}
                    <Dropdown className={'mr-1 d-inline-block'}>
                        <Dropdown.Toggle variant={'outline-secondary'}>
                            <FontAwesomeIcon icon={'external-link-alt'}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>
                                Экспорт данных с текущей страницы
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => downloadPage('xlsx', 'tblToExcl', 'grant_project')}>
                                <IconText text={'Excel'} icon={'file-excel'} icon_color={'green'}/>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </FormikProvider>
        </div>
        <FormikProvider value={valuesFormik}>
            <Table id={'tblToExcl'} striped bordered hover size={'sm'} responsive='xl'>
                <thead>
                <tr>
                    <th></th>
                    <th>План, руб.</th>
                    <th>Фактические расходы с обязательствами, руб.</th>
                    <th>Остаток, руб.</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <th>Заработная плата с начислениями</th>
                    <td><Input id={'plan.wage'}
                               name={'plan.wage'}
                               type={'number'}
                               disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)}
                               handleChangeValue={valuesFormik.handleChange}
                               value={valuesFormik.values.plan.wage}
                    /></td>
                    <td><Input id={'fact.wage'}
                               name={'fact.wage'}
                               type={'number'}
                               disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)}
                               handleChangeValue={valuesFormik.handleChange}
                               value={valuesFormik.values.fact.wage}
                    /></td>
                    <td>
                        {valuesFormik.values.remainder.wage}
                    </td>
                </tr>
                <tr>
                    <th>Закупки</th>
                    <td><Input id={'plan.procurement'}
                               name={'plan.procurement'}
                               type={'number'}
                               disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)}
                               handleChangeValue={valuesFormik.handleChange}
                               value={valuesFormik.values.plan.procurement}
                    /></td>
                    <td><Input id={'fact.procurement'}
                               name={'fact.procurement'}
                               type={'number'}
                               disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)}
                               handleChangeValue={valuesFormik.handleChange}
                               value={valuesFormik.values.fact.procurement}
                    /></td>
                    <td>
                        {valuesFormik.values.remainder.procurement}
                    </td>
                </tr>
                <tr>
                    <th>Командировки</th>
                    <td><Input id={'plan.business_trips'}
                               name={'plan.business_trips'}
                               type={'number'}
                               disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)}
                               handleChangeValue={valuesFormik.handleChange}
                               value={valuesFormik.values.plan.business_trips}
                    /></td>
                    <td><Input id={'fact.business_trips'}
                               name={'fact.business_trips'}
                               type={'number'}
                               disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)}
                               handleChangeValue={valuesFormik.handleChange}
                               value={valuesFormik.values.fact.business_trips}
                    /></td>
                    <td>
                        {valuesFormik.values.remainder.business_trips}
                    </td>
                </tr>
                <tr>
                    <th>Всего</th>
                    <td>
                        {valuesFormik.values.total.plan}
                    </td>
                    <td>
                        {valuesFormik.values.total.fact}
                    </td>
                    <td>
                        {valuesFormik.values.total.remainder}
                    </td>
                </tr>
                </tbody>
            </Table>
            <div>
                {Object.keys(MONTHS).map((item) =>
                    <Input key={`month-${item}`}
                           id={`months.${item}`}
                           name={`months.${item}`}
                           type={'number'}
                           disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)}
                           handleChangeValue={valuesFormik.handleChange}
                           value={valuesFormik.values.months?.[item]}
                           label={MONTHS?.[item]}
                    />,
                )}
            </div>
        </FormikProvider>
    </>
}

export default GrantProject
