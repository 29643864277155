import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { getRequest } from '../../../actions/common'
import ReactTableIndicator from '../../../components/tables/react_table_indicator'
import { IconText } from '../../../components/icon_txt'
import { SelectReact } from '../../../components/form/select'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../rbac/constant'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../auth'

const IndicatorProjects = ({ title, section_name, labels, select_fields }) => {
    const { userHasPermissions } = useAbac()

    const [indicator_project, setIndicatorProject] = useState(null)
    const [indicator, setIndicator] = useState(null)
    const [project, setProject] = useState(null)
    const [total_indicator_section, setTotalIndicatorSection] = useState(null)
    const [select_data, setSelectData] = useState({})
    const [select_values, setSelectValues] = useState({})
    const user = useContext(AuthContext)

    React.useEffect(() => {
        document.title = `${title} | ИС «ПИШ»`
    })

    useEffect(() => {
        if (!indicator_project) {
            getRequest(`indicator_${section_name}`, setIndicatorProject, { user_id: user?.id })
        }
    }, [indicator_project])

    useEffect(() => {
        if (!indicator) {
            getRequest('indicator', setIndicator, { type_data: 'all' })
        }
    }, [indicator])

    useEffect(() => {
        if (!project) {
            getRequest(`${section_name}`, setProject, { user_id: user?.id })
        }
    }, [project])

    useEffect(() => {
        if (!total_indicator_section) {
            getRequest(`total_indicator_${section_name}`, setTotalIndicatorSection, { user_id: user?.id })
        }
    }, [total_indicator_section])

    useEffect(() => {
        if (Object.keys(select_data).length === 0 && indicator !== null && project !== null) {
            let value = {}
            value['indicator'] = indicator
            value[section_name] = project
            setSelectData(value)
        }
    }, [project, indicator])

    const btnFilter = () => {
        let card = document.getElementById('card-filter')
        if (card && card.style.display === 'none') {
            card.style.display = 'block'
        } else if (card && card.style.display === 'block') {
            card.style.display = 'none'
        }
    }

    const FilterSelects = () => {
        if (select_fields && Object.keys(select_data).length !== 0) {
            let elements = []
            select_fields.forEach((field) => {
                let options = []
                select_data[field].forEach((element) => {
                    options.push({
                        value: element.id,
                        label: element.title,
                    })
                })
                elements.push(
                    <Col key={field}>
                        <div className='form-group'>
                            <Form.Label>{labels[field]}</Form.Label>
                            <SelectReact options={options} setSelectState={true} select_key={field}
                                         select_values={select_values}
                                         setState={setSelectValues}
                                         isClearable={true}
                                         value={select_values[field]}
                                         selectID={`${field}_select`}/>
                        </div>
                    </Col>,
                )
            })
            return elements
        }
        return null
    }

    const refreshFilter = () => {
        setSelectValues({})
        getRequest(`indicator_${section_name}`, setIndicatorProject, { type_data: 'all' })
        getRequest('indicator', setIndicator, { type_data: 'all' })
        getRequest(`${section_name}`, setSection, { type_data: 'all' })
    }

    const onSubmitForm = (e) => {
        e.preventDefault()
        for (const [key, value] of Object.entries(select_values)) {
            let setFunc = key === 'indicator' ? (v) => setIndicator([v]) : (v) => setSection([v])
            getRequest(key, setFunc, {}, value, null, null, true)
        }
        getRequest(`indicator_${section_name}`, setIndicatorProject, select_values)
    }


    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                {title}
            </h1>
            <div className='button-actions mb-3'>
                <Button variant='secondary' onClick={btnFilter}>
                    <IconText text={'Форма поиска'} icon={'search'}/>
                </Button>
            </div>

            <Card style={{ display: 'none', zIndex: 4 }} id='card-filter' className={'mb-3'}>
                <form onSubmit={onSubmitForm}>
                    <Card.Body>
                        <Row className='row-cols-1 row-cols-md-2 row-cols-lg-2'>
                            <FilterSelects/>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button type='submit'>Найти</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </form>
            </Card>

            <ReactTableIndicator data={indicator_project} total={total_indicator_section}
                                 indicators={indicator} header_data={project}/>
        </React.Fragment>
    )
}

export default IndicatorProjects
