import * as React from 'react'
import ReactBreadcrumb from '../../../../components/breadcrumbs'
import ReactFilterTable from '../../../../components/tables/react_table_filters/react_table_filters'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS, ROLES } from '../../../../rbac/constant'
import { Badge, Button } from 'react-bootstrap'
import { IconText } from '../../../../components/icon_txt'
import { AuthContext } from '../../../../auth'
import { FIELDS, LABELS, STATUS_BADGE_CLASS, STATUS_LABELS,
         STATUS_ACCEPT, STATUS_NEW,
         RID_STATUS, RID_TYPES, RID_DOCUMENT_TYPES, RID_FINANCING_METHOD,
       } from './constant'
import { getLabelFromOptions, Messages } from '../../../../utils/utils'
import { putRequest } from '../../../../actions/common'
import { useAbac } from 'react-abac'

const RidInternal = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [rid, setRid] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'title',  'type', 'document_type',
             'project', 'application_status'],
        'Скрытые / Фиксированные колонки': ['updated_by', 'created_at', 'updated_at', 
            'created_by', 'direction_rid_by', 'organization_name', 'patent_office', 
            'direction_by', 'authors_input', 'number',  
            'messages', 'fin_method', 'application_date', 'application_number',  
        ],
    })


    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        }
        if (['country', 'patent_office'].includes(key) && field) {
            return <span>{field.title}</span>
        }
        if (key === 'event') {
            return field?.title_short ? `(${field?.title_short}) ${field?.title}`: field?.title
        }
        if (['project', 'job_contract'].includes(key) && field) {
            return <span>{field.title_short}</span>
        }
        if (key === 'type') {
            return <span>{getLabelFromOptions(RID_TYPES, field)}</span>
        }
        if (key === 'fin_method') {
            return <span>{getLabelFromOptions(RID_FINANCING_METHOD, field)}</span>
        }
        if (key === 'document_type') {
            return <span>{getLabelFromOptions(RID_DOCUMENT_TYPES, field)}</span>
        }
        if (key === 'application_status') {
            let ridStatusBgColor = (field === -1) ? 'danger' : (field === 2) ? 'warning' : 'success'
            let ridStatusColor = (field === 2) ? 'dark' : 'white'
            return(
                <span>
                    <Badge bg={ridStatusBgColor} style={{ color: ridStatusColor }}>
                        {getLabelFromOptions(RID_STATUS, field)}
                    </Badge>
                </span>
            ) 
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'rid'}/>
        }
        if (key === 'title') {
            return <span>
                {field?.ru
                    ? (<span>
                        <Badge bg={'secondary'} style={{ color: 'white' }}>
                            Ru:
                        </Badge>
                        <span>{field?.ru}</span>
                    </span>)
                    : null
                }
                <br/>
                {field?.en 
                    ? (<span>
                        <Badge bg={'secondary'} style={{ color: 'white' }}>
                            En:
                        </Badge>
                        <span>{field?.en}</span>
                    </span>)
                    : null
                }
            </span>
        }
        return null
    }

    const addNewRid = (state) => {
        putRequest('rid_internal', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW,
            project: user?.project?.id,   
            is_draft: true,     
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/rid/rid-internal/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (<React.Fragment>
        <ReactBreadcrumb/>
        <div className='section-header sticky-top'>     
            <h1 className={'page-header'}>
                РИДы (внутренний мониторинг)
            </h1>
            { userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                && <div className='button-actions mb-3'>
                    <Button variant={'info'} className={'mb-3'} onClick={() => {
                            navigate('/rid/rid-external')
                        }}>
                        <IconText icon={'circle'} text={'Внешний мониторинг'}/>
                    </Button> 
                    <Button variant={'info'} disabled={true} className={'mb-3'} onClick={() => {
                            navigate('/rid/rid-internal')
                        }}>
                        <IconText icon={'dot-circle'} text={'Внутренний мониторинг'}/>
                    </Button> 
                </div> 
            }
        </div>

        {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction_rid) 
        || user?.roles?.includes(ROLES.project_manager) || user?.roles?.includes(ROLES.direction)
            ? <React.Fragment>
                <div className={'button-actions'}>
                    {!user?.roles?.includes(ROLES.direction)
                        ? <Button variant={'success'} className={'mb-3'} onClick={() => {
                            addNewRid()
                        }}>
                            <IconText icon={'plus'} text={'Создать'}/>
                        </Button> : null}
                    {user?.roles?.includes(ROLES.admin) 
                    || user?.roles?.includes(ROLES.direction_rid)
                    || user?.roles?.includes(ROLES.direction)
                        ? <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                            addNewRid({ monitoring: true })
                        }}>
                            <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                        </Button> : null}
                </div>

            </React.Fragment> : null}

        <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                          filtering={filtering}
                          data={rid} setFunc={setRid} page={1} url={'/rid/rid-internal/item'}
                          url_update={'/rid/rid-internal/item'}
                          get_title={'rid_internal'}
                          checkField={checkField}
                          permission={PERMISSIONS.MANAGE_RID}
                          urlImport={'/api/rid_internal_import_request/'}
                          templateFile={'import_rid_template.xlsx'}
                          fields={FIELDS}
        />
    </React.Fragment>)
}

export default RidInternal
